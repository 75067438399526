/* eslint-disable no-unused-vars */
import { h } from "preact";
import "../../style/components/blocks/add_payment_method.css";

export default function AddPaymentMethod(props) {
  const { label = "+ Add Payment Method", addPaymentMethod } = props;
  return (
    <button className="add-payment-method site-button" onClick={addPaymentMethod}{...props}>
      <div className="add-payment-method__label">
        <span className="add-payment-method__text">{label}</span>
      </div>
    </button>
  );
}
