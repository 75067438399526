// Utility
import authWrapper from '../../../Utilities/authGarage/authGarage';
// State
import { CoreState } from '../../../Utilities/contextLibrary';
// Hooks
import { useContext, useEffect } from 'preact/hooks';


const Logout = ({ phase }) => {
	const { coreState, coreDispatch } = useContext(CoreState);
	const initiateLogout = async () => {
		try {
				if (coreState.handshake?.pkce) {
					console.log('logout with pkce');
					coreDispatch({type: 'logoutPhase', payload: 'completeLogout'});
					authWrapper.logout(coreState.handshake.pkce);
				} else {
					console.log('logout with accestoken')
					authWrapper.handler('logout', { AccessToken: coreState.tokens.access_token })
					coreDispatch({type: 'logoutPhase', payload: 'completeLogout'});
				}
				
			} catch (e) { 
				console.log('ERROR:', e)
			}
			
		}
		const completeLogout = () => {
			console.log('completing  logout');
			coreDispatch({ type: 'tokens', payload: {} });
			coreDispatch({ type: 'userinfo', payload: null });
			coreDispatch({ type: 'authPhase', payload: 'initiate' });
			coreDispatch({ type: 'handshake', payload: null });
			coreDispatch({ type: 'isAuth', payload: false });
			window.localStorage.removeItem('preact:authDest');
	}
	useEffect(() => {
		switch (phase) {
			case 'initiateLogout': initiateLogout();
			case 'completeLogout': completeLogout();
		}
		// Complete purge and bailout.
		window.localStorage.removeItem('preact:coreState');
		window.location = '/';

	},['redundancyGate']);

	return (
		<>
			<h2>Logging Out...</h2>
		</>
	);
}


export default Logout;