import {h} from 'preact';
import { useContext, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import api from '../../../../Utilities/API/api';
import { CoreState } from '../../../../Utilities/contextLibrary';

const AddTeamMember = () => {
    const {coreState, coreDispatch} = useContext(CoreState);
    const refs = {
        name: {
            first: useRef(null),
            last: useRef(null)
        },
        email: useRef(null),
        phone: useRef(null),
        type: useRef(false),
        notify: useRef(true)
    }
    const handleSubmit = async event => {
        event.preventDefault();
        const employeeObject = {
            name: {
                first: refs.name.first.current.value,
                last: refs.name.last.current.value
            },
            email: refs.email.current.value,
            phone: refs.phone.current.value,
            type: refs.type.current.value,
            // notify: refs.notify.current.value
        };

       const {status, headers, response} =  await api.post(coreState.tokens.id_token, 'employee/', employeeObject)
            
       if (status != 200) {
           // console.log('employee register error: ', status);
           window.alert('there was an issue with creating this employee, if this is your first time seeing this error please refresh the page and try again');
           window.location.reload();
       } else {
           route('/dashboard/team');
       }

                
    }
    return (
        <section class="bg-white padding-large column">
            <a href="/dashboard/team" class="button justify-center back">Back to Team</a>
            <div class="margin-y-1">
                <h2 class="display2 -extra-lite align-center margin-y-1">Add Team Member</h2>
                <form
                class="col-1-4 margin-x-auto"
                onSubmit={handleSubmit}>
                    <div class={`${`input_container`}`}>
                    <input 
                    ref={refs.name.first}
                    required
                    type="text"
                    id="firstName"
                    />
                        <label for="firstName">First Name*</label>
                    </div>
                    <div class={`${`input_container`}`}>
                    <input 
                    ref={refs.name.last}
                    required
                    type="text"
                    id="lastName"
                    />
                        <label for="lastName">Last Name*</label>
                    </div>
                    <div class={`input_container`} >
                    <input 
                    ref={refs.email}
                    required
                    type="text"
                    id="email"
                    />
                        <label for="email">Email*</label>
                    </div>
                    <div class={`input_container`} >
                    <input 
                    ref={refs.phone}
                    required
                    type="phone"
                    id="phone"
                    />
                        <label for="phone">Phone*</label>
                    </div>
                    <div class={`input_container`} >
                    <select 
                    ref={refs.type}
                    required
                    type="select"
                    id="type"
                    >
                        <option value="employee">Team Member</option>
                        {coreState.role.includes('owner') && <option value="admin">Admin</option>}
                    </select>
                        <label for="type">Role</label>
                    </div>
                    <div class="margin-y-1">
                        <input
                                class="terms"
                                id="notify"
                                ref={refs.notify}
                                type="checkbox"
                            />
                            <label class="display5" for="notify">Notify this user with their login info.</label>                
                    </div>
                    <button class="button -blue" style="width: 100%;" type="submit">Add User</button>
                </form>
            </div>
        </section>
    )
}

export default AddTeamMember;