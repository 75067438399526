import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Hero from '../../components/Hero/hero';
import Block from '../../components/CTA Block/cta_block';
import BlogSection from '../../components/Blog Section/blog_section';
import { prismicBlogs, prismicClient } from '../../Utilities/prismic/prismicClient';
import Loader from '../../components/Loading/loading';

const About = () => {
	const [doc, setDocData] = useState();
	const [blog, setBlogData] = useState([]);
	useEffect(() => {
		prismicClient('about', 'about').then(res => {
			setDocData(res.data)
		})
		prismicBlogs('blog_post').then(res => {
			setBlogData(res.results)
		})
	}, []);
	return (
		doc && blog ? (
			<>
			<Hero content={doc}/>
			<Block content={doc}/>
			<BlogSection blogs={blog}/>
			</>
		) : <Loader />
	)
};

export default About;
