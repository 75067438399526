/* eslint-disable no-unused-vars */
import { h, createContext } from "preact";
import { useToggle } from "../../Utilities/useToggle";

export const SiteModalContext = createContext({});

export const SiteModal = (props) => {
  const { children } = props;

  const [isActive, setIsActive] = useToggle();

  return (
    <SiteModalContext.Provider value={{ isActive, setIsActive }}>
      {children}
    </SiteModalContext.Provider>
  );
};
