import { h } from "preact";
import "../../style/components/blocks/select_input.css";

export default function SelectField(props) {
  const { label, name, options, isValid = true } = props;

  return (
    <div className="select-field" {...props}>
      {label && (
        <label class="select-field__label" htmlFor={name}>
          {label}
        </label>
      )}
      <select {...props} name={name} id="select" className={`select-field__input ${!isValid && "select-field__input--error"}`}>
        {options &&
          options.map((item, index) => (
            <option
              key={index}
              value={item}
              className="select-field__option"
            >
              {item}
            </option>
          ))}
      </select>
    </div>
  );
}
