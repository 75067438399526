import { h } from "preact";
// Hooks
import { useEffect, useReducer, useState, useCallback } from 'preact/hooks';
// Application state

import { CoreState } from '../Utilities/contextLibrary';
// utility functions
import Match from 'preact-router/match';
import { Router } from 'preact-router';
import verifyRoute from "../Utilities/routing/index.js";

import LogRocket from 'logrocket';
import analytics from '../Utilities/analytics';
// shared component import
import Header from './Header';
import Footer from './Footer/footer';
// public pages import
import Home from '../Containers/Home/home';
import About from '../Containers/About/about';
import Blog from '../Containers/Blog/blogs';
import BlogPost from '../Containers/Posts/posts';
import Auth from '../Containers/Auth/auth';
import Login from '../Containers/Auth/Login/login';
import Logout from '../Containers/Auth/Logout/logout';
import NotFound from '../Containers/Not Found/notfound';
import Maintenance from '../Containers/Maintenance/maintenance';
import Terms from '../Containers/Terms/terms';
// Auth Containers Pages
import Dashboard from '../Containers/Dashboard/dashboard';
import Account from '../Containers/Dashboard/Account/account';
import Register from '../Containers/Register/register';

import Benefit from '../Containers/Benefit/benefit';

import Team from '../Containers/Dashboard/Team/team';
import ManageTeamMember from '../Containers/Dashboard/Team/Team Manage/team_manage';
import AddTeamMember from '../Containers/Dashboard/Team/Team Add/team_add';

import EmployeeValidation from '../Containers/Employee Status/employee_validate';
import EmployeeRegister from '../Containers/Employee Status/employee_register';
import Guides from '../Containers/Guides/guides';
import Upgrade from '../Containers/Dashboard/Upgrade/upgrade';
import Confirmation from "../Containers/Confirmation";
import Failed from "../Containers/Failed";


analytics.page()

LogRocket.init('flkczz/aflac-cyber-protection', {
	dom: {
	  textSanitizer: false,
	  inputSanitizer: true
	}
  });



export default () => {
	const [ coreState, coreDispatch ] = useReducer(
		// Define the reducer behavior
		(state, {type, payload}) => {
			if (type === 'reset') return payload
			else {
				const newState = { ...state, [type]: payload };
				window.localStorage.setItem(
					'preact:coreState',
					JSON.stringify(newState)
				);
				return newState;
			}
		},
		// Search localStorage for initial state...
		JSON.parse(window.localStorage.getItem('preact:coreState')) ||
		// ...otherwise derive from template
		{ isAuth: false, authPhase: 'initiate', tokens: {}, userinfo: {} }
	);

	const [highContrast, setContrast] = useToggle();
	function useToggle() {
		const [state, setState] = useState(false);
		const toggle = useCallback(() => setState(state => !state), []);
		return [state, toggle]
	}

	return (
		<CoreState.Provider value={{ coreState, coreDispatch }}>
		<div id="app" class={`${highContrast ? 'high-contrast' : 'normal'} `}>
			<Header props={{highContrast, setContrast, coreState, coreDispatch}} />
			<Router onChange={async e => await verifyRoute(e, coreState, coreDispatch)}>

				{/* PUBLIC */}
				<Home doc="homepage" path="/" />
				<About doc="about" path="/about" />
				<Blog doc="blog" path="/blog" />
				<BlogPost doc="post" path="/blog/:uid" />
				<Auth path="/auth" />
				<Login path="/login/:rest*" to="/maintenance"  />
				<Logout path="/logout" phase={coreState.logoutPhase}/>
				<NotFound path="/not_found" />
				<Maintenance path="/maintenance" />
				<Terms path="/terms" />

				{/* Private */}
				<Account path="/dashboard/account/:rest*" auth={true} />
				<Upgrade path="dashboard/upgrade/:rest*" auth={true} />
				<Dashboard path="/dashboard/:rest*" auth={true} active={true} />
				<Guides path="/guides" auth={true} />

				<EmployeeValidation path="/employee/register/:rest*" auth={true} />
				<EmployeeRegister path="/employee/register/confirm" auth={true} />

				{/* <Register path="/register/:rest*" auth={true} /> */}
				<Register path="/register/:rest*" auth={true} />
				<Confirmation path="/register/confirm/:rest*" auth={true} />
				<Failed path="/register/failed" auth={true} />
				<Team path="/dashboard/team/:rest*" auth={true} role={['employee.owner', 'employee.admin']} />
				<AddTeamMember path="/dashboard/team/add/:rest*" auth={true} role={['employee.owner', 'employee.admin']} />
				<ManageTeamMember path="/dashboard/team/manage/:rest*" auth={true} role={['employee.owner', 'employee.admin']} />
				<Benefit path="/benefit" auth={true} />

				{/* Path based state change */}
				<Match path="/benefit">
				{({ matches, path }) => matches &&  (
					coreDispatch({ type: 'header_view', payload: path })
				) }
				</Match>
				<Match path="/maintenance">
				{({ matches, path }) => matches &&  (
					coreDispatch({ type: 'header_view', payload: path })
				) }
				</Match>
				<Match path="/">
					{({ matches, path }) => matches &&  (
						coreDispatch({ type: 'header_view', payload: path })
					) }
				</Match>
				<Match path="not_found">
					{({ matches, path }) => matches &&  (
						coreDispatch({ type: 'header_view', payload: path })
					) }
				</Match>
				<Match path="*">
					{ ({ matches, path }) => matches && (
						coreDispatch({ type: 'header_view', payload: path })
					) }
				</Match>
			</Router>
			<Footer />
		</div>
		</CoreState.Provider>
	)
}
