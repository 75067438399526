import { route } from "preact-router";
import { useContext, useState } from "preact/hooks";
import authWrapper from "../../Utilities/authGarage/authGarage";
import { prismicClient } from "../../Utilities/prismic/prismicClient";
import Button from "../Button";
import Loader from "../Loading/loading";

const UserType = ({context}) => {
    const { authState, setAuthState } = useContext(context);
    const [ doc, setDoc ] = useState();
    prismicClient('identity_and_purchasing_flows', 'registration').then(res => {
        setDoc(res.data)
    });

    function routeSignUp() {
        setAuthState({
            ...authState,
            stage: 'sign-up'
        })
    }
    function routeSignIn() {
        setAuthState({
            ...authState,
            stage: 'login'
        })
    }

    if (!doc) return <div style={{ padding: "25%"}}><Loader /></div>
    return (
        <>
            <h1 class="display1 -extra-lite align-center padding-y-1 ">Continue to {window.localStorage.getItem('preact:authDest')?.split(/\W/)[1] || 'Login'} as...</h1>
            <div
                style={{
                    width: "100%",
                    marginTop: "50px",
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gridAutoRows: "1fr",
                    gap: "24px",
                }}
            >
                <div class="align-center margin-auto padding-y-1">
                    <div class="row space-around">
                        <span class="card padding-medium bg-lighter-blue margin-y-1" id="agent" style="max-width: 592px; height: 452px;">
                            <img class="-icon" src="/assets/images/icons/icon-agent.png" alt="agent icon" />
                            <h3 class="display3 -lite" style="font-size: 36px;">{doc?.user_type[0].user_type_title[0].text}</h3>
                            <p class="margin-y-1" style="font-size: 22px; font-weight: 400px; line-height: 30px;">{doc.user_type[0].user_type_description[0].text}</p>
                            {/* <button onClick={lwaSSO} class="button -blue" id="Aflac">Continue as an Agent</button> */}
                            <Button 
                                label='Continue as an Agent'
                                onClick={lwaSSO}
                                />
                        </span>
                        <span class="card padding-medium bg-lighter-blue margin-y-1" id="agent" style="max-width: 592px; height: 452px;">
                            <img class="-icon" src="/assets/images/icons/icon-broker.png" alt="broker icon" />
                            <h3 class="display3 -lite" style="font-size: 36px;">{doc?.user_type[1].user_type_title[0].text}</h3>
                            <p class="margin-y-1" style="font-size: 22px; font-weight: 400px; line-height: 30px;">{doc.user_type[1].user_type_description[0].text}</p>
                            {/* <a class="button -blue" onClick={brokerUser}>Continue as a Broker</a> */}
                            <Button 
                                blackOutline
                                onClick={brokerUser}
                            >
                                <svg 
                                    width="24" 
                                    height="25" 
                                    viewBox="0 0 24 25" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path 
                                        fill-rule="evenodd" 
                                        clip-rule="evenodd" 
                                        d="M20.64 12.8712C20.64 12.233 20.5827 11.6194 20.4764 11.0303H12V14.5116H16.8436C16.635 15.6366 16.0009 16.5898 15.0477 17.228V19.4862H17.9564C19.6582 17.9194 20.64 15.6121 20.64 12.8712Z" 
                                        fill="#4285F4" 
                                    />
                                    <path 
                                        fill-rule="evenodd" 
                                        clip-rule="evenodd" 
                                        d="M11.9998 21.6665C14.4298 21.6665 16.467 20.8606 17.9561 19.486L15.0475 17.2278C14.2416 17.7678 13.2107 18.0869 11.9998 18.0869C9.65567 18.0869 7.67158 16.5037 6.96385 14.3765H3.95703V16.7083C5.43794 19.6496 8.48158 21.6665 11.9998 21.6665Z" 
                                        fill="#34A853" 
                                    />
                                    <path 
                                    fill-rule="evenodd" 
                                    clip-rule="evenodd" 
                                    d="M6.96409 14.3763C6.78409 13.8363 6.68182 13.2595 6.68182 12.6663C6.68182 12.0731 6.78409 11.4963 6.96409 10.9563V8.62451H3.95727C3.34773 9.83951 3 11.2141 3 12.6663C3 14.1186 3.34773 15.4931 3.95727 16.7081L6.96409 14.3763Z" fill="#FBBC05" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 7.24605C13.3211 7.24605 14.5075 7.70014 15.4402 8.59196L18.0216 6.01059C16.4629 4.55832 14.4257 3.6665 11.9998 3.6665C8.48158 3.6665 5.43794 5.68332 3.95703 8.62469L6.96385 10.9565C7.67158 8.82923 9.65567 7.24605 11.9998 7.24605Z" fill="#EA4335" />
                                </svg>
                                Sign in with Google
                            </Button>
                            <p style={{ color: "black", marginTop: "28px"}}>Dont have one of these? <a style={{ color: "black"}} onClick={routeSignIn} href="/login">Sign in</a> or <a style={{ color: "black"}} onClick={routeSignUp} href="/login">Create Account Manually</a></p>
                        </span>
                    </div>
                </div>
            </div>
        </>

    )
}


const lwaSSO = async e => {
    e.preventDefault();
    const handshake = await authWrapper.generate();
    const { pkce, challenge } = handshake;
    const provider = 'Aflac';
    authWrapper.initiate({ pkce, challenge, provider });
}

const brokerUser = async e => {
    e.preventDefault();
    const handshake = await authWrapper.generate();
    const { pkce, challenge } = handshake;
    const provider = 'Google';
    authWrapper.initiate({ pkce, challenge, provider });
};

export default UserType;