import { h } from 'preact';

const FAQ = ({ content }) => {
   return ( 
    <section id="faq" class={`block faq`}>
        <h2 class={`display4-lite display1`}>
            Frequently Asked Questions
        </h2>
        {
            content.accordion.map((item, index) => {
                return (
                    <details key={index}>
                        <summary>
                            <h3>
                                {item.accordion_label[0].text}
                            </h3>
                        </summary>
                        <div class={`-content`}>
                            <ul>
                                {item.accordion_desc.map(desc => {
                                    return desc.type == 'paragraph' ?
                                        <p>{desc.text}</p>
                                    :   
                                        <p> • {desc.text}</p>
                                })}
                            </ul>
                        </div>
                    </details>
                )
            })
        }
•    </section>
)
};
export default FAQ;
