import { h } from "preact";
import { Link } from "preact-router/match";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import { useToggle } from "../../Utilities/useToggle";

import "../../style/components/blocks/account_overview_nav.css";

const NAV_LIST_CLASS = "account-overview-nav__list";
const NAV_LINK_CLASS = "account-overview-nav__link";
const ACTIVE_CLASS = "link-active";

export const Nav = (props) => {
  const { items = [] } = props;

  const [currentActive, setCurrentActive] = useState("Account Overview");
  const [menuIsOpen, setMenuIsOpen] = useToggle();
  const navRef = useRef(null);

  const onLinkClick = useCallback((e) => {
    const text = e.target.closest("a").textContent;
    setCurrentActive(text);
    setMenuIsOpen();
  }, []);

  useEffect(() => {
    const nav = navRef.current.querySelector(`.${NAV_LIST_CLASS}`);

    if (nav) {
      const links = nav.getElementsByClassName(NAV_LINK_CLASS);

      for (let index = 0; index < links.length; index++) {
        const link = links[index];

        if (link.href.includes(window.location.pathname))
          setCurrentActive(link.textContent);
      }
    }
  }, []);

  return (
    <nav className="account-overview-nav" ref={navRef}>
      <div
        className="account-overview-nav__active"
        role="button"
        onClick={setMenuIsOpen}
      >
        <span className="account-overview-nav__active-label">
          {currentActive}
        </span>

        <div
          className="account-overview-nav__active-icon"
          style={{ transform: menuIsOpen ? "rotate(180deg)" : "rotate(0)" }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 9L12 15L18 9"
              stroke="#393939"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <ul
        className="account-overview-nav__list"
        style={{ display: menuIsOpen ? "block" : "none" }}
      >
        {items &&
          items.map((item, index) => (
            <li className="account-overview-nav__list-item" key={index}>
              <Link
                className={`link margin-y-1 account-overview-nav__link ${
                  item.href === window.location.pathname ? ACTIVE_CLASS : ""
                }`}
                activeClassName={ACTIVE_CLASS}
                href={item.href}
                onClick={onLinkClick}
              >
                {item.label}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  );
};
