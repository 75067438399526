// Components
import PlanSelectionPage from "../../../components/PlanSelection/PlanSelectionPage";
const Upgrade = () => {
	return (
		<section class={`bg-white margin-auto padding-large`}>
			<PlanSelectionPage upgrade={true}/>
		</section>
	)
};

export default Upgrade;
