/* eslint-disable no-unused-vars */
import { h } from "preact";
import HistoryItem from "./HistoryItem";

import "../../style/components/blocks/history_item.css";
import "../../style/components/blocks/history_items.css";

export default function HistoryItems({ props }) {
  const { invoices } = props;

  return (
    <div className="billing-history-items">
      <h2 className="display2 -extra-lite">Billing History</h2>
      <div className="history-item__row history-item__row--heading">
        <div className="history-item__block">
          <span className="history-item__label history-item__label--heading">
            Date
          </span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label history-item__label--heading">
            Invoice #
          </span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label history-item__label--heading">
            Amount
          </span>
        </div>
        {/* <div className="history-item__block">
          <span className="history-item__label history-item__label--heading">
            Team Members
          </span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label history-item__label--heading">
            Desktop
          </span>
        </div>
        <div className="history-item__block">
          <span className="history-item__label history-item__label--heading">
            Mobile
          </span>
        </div> */}
        <div className="history-item__block history-item__block--actions">
          <span className="history-item__label history-item__label--heading history-item__label--heading-actions">
            Actions
          </span>
        </div>
      </div>

      <div className="billing-history-items__hr" />

      {invoices &&
        invoices.map((item, index) => <HistoryItem key={index} {...item} />)}
    </div>
  );
}
