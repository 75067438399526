import  {h} from   "preact" ;
import { useState, useCallback,useRef } from "preact/hooks";
import InputField from "../InputField";
import validate from "../../Utilities/validate";

export default function OrderSummaryInput(props) {
    const { name, regex = `^[A-Za-z0-9_-][^@!#$%^&*();:'/\"/{}]{1,32}$`, formRef, regexType, checkIfValid = false, label } = props;
    const [ isValidated, setIsValidated ] = useState();
    const handleValidation = useCallback(() => {
        const formData = new FormData(formRef.current);
        const input = formData.get(name);
        // const validate = regex;
        // const rgx = new RegExp(validate);
        // const isValid = rgx.test(input);
        // if (input.contains("[")) return setIsValidated(false);
        const isValid = validate(input, regexType);
        if (label === "Phone (Optional)") return setIsValidated(true);
        if (regexType === "phone") {
            setIsValidated(isValid);
        }else {
            setIsValidated(!isValid);
        }
        console.log(name,"is Valid:", isValid);
        if (!checkIfValid) return;
        checkIfValid();
      }, [isValidated]);
    return (
        <InputField
            name={name}
            isValidated={isValidated}
            onChange={handleValidation}
            data-validate={isValidated}
            {...props}
        />
    );
function isFunction(fn) {
        return Object.prototype.toString.call(fn) == '[object Function]'
      }
}