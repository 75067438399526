export default {
    domain: process.env.PREACT_APP_COGNITO_ENDPOINT,
    appClientId: process.env.PREACT_APP_COGNITO_CLIENT_ID,
    userPoolId:  process.env.PREACT_APP_COGNITO_USERPOOL_ID,
    redirectURI: process.env.PREACT_APP_COGNITO_REDIRECT_URI,
    logoutURI: process.env.PREACT_APP_COGNITO_REDIRECT_URI_SIGNOUT,
    provider: {
        aflac: "Aflac",
        google: "Google",
        microsoft: "Microsoft"
    },
    scope: process.env.PREACT_APP_COGNITO_SCOPE
};
