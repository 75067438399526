export default function (input, type) {
  switch (type) {
    case 'address':
      return input.match(/^\d+\s[A-z]+\s[A-z]+/i);
    case 'city':
      return input.match(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/i);
    case 'email':
      // eslint-disable-next-line
      return input.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i);
    case 'name':
      return input.match(/^[A-Za-z\s,.]{2,}[.]{0,1}[A-Za-z\s,.]{0,}$/i);
    case 'text':
      return input.match(/[^a-zA-Z0-9'-\s]+$/i);
      // return input.match(/^[a-zA-Z0-9;!@#$&()'\\-`.+,/\\"\s]{1,}$/i);
    case 'url':
      return input.match(/^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/i);
    case 'domain':
      if (input.split('--').length - 1 >= 1) {
        return null;
      } else {
        return input.match(/^[a-zA-Z0-9-]+$/);
      }
    case 'phone':
      return input.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
    case 'full-name':
      return input.match(/^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)/);
    case 'password':
      return input.match(/^\w{6,}$/);
    case 'date':
      return input.match(/^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/);
    case 'memory-date':
      return input.match(/^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](17|18|19|20)\d\d$/);
    case 'year':
      return input.match(/^\d{4}$/);
    case 'number':
      return input.match(/^\d{1,}$/);
    case 'device-count':
      return input.match(/^\d{1,}$/);
    case 'bid':
      return input.match(/^[0-9,]*(?:\.[0-9]{2})?$/);
    case 'routing':
      return input.match(/^\d{9}$/);
    default:
  }
};
