import { useContext, useEffect, useLayoutEffect, useRef, useState } from "preact/hooks"
import { CoreState } from '../../Utilities/contextLibrary';
import api from "../../Utilities/API/api";
import nativeAuth from "../../Utilities/authGarage/native";
import { route } from "preact-router";

export default () => {
    const { coreState, coreDispatch } = useContext(CoreState);
    // const { validationState, setValidationState } = useContext(context);
    const [ helperText, setHelperText ] = useState("Please confirm your info below");

    // const params = new URLSearchParams(window.location.search)
    const token = coreState.tokens.id_token
    const accessToken = coreState.tokens.access_token

    const refMap = {
        first: useRef(null),
        last: useRef(null),
        phone: useRef(null)
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const phoneNumber = parsePhoneToDigitsOnly(refMap.phone.current.value)
        if (phoneNumber.length != 10) {
            // console.log(phoneNumber)
            setHelperText('invalid phone number, please ensure that you have 10 digits and no special characters (ie. 8005551234')
            return
        }

        setHelperText('Saving your info...')
        const { response, status, headers } = await  nativeAuth.updateAttributes({
            accessToken: accessToken,
            attributes: {
                given_name: refMap.first.current.value,
                family_name: refMap.last.current.value,
                phone_number: '+1' + phoneNumber
            }
        });

        // console.log(response, status, headers)

        if (status != 200) { setHelperText('There was a problem saving your information'); return; }

        setHelperText('Setting up your account...')

        const res = await api.get(
            token,
            'validate/employee/confirm',
            'apiv5'
        )
        if (res.status != 200) { setHelperText('Account confirmation failed, please contact support'); return; }

        const userInfo = await nativeAuth.userinfo(accessToken)
        // console.log(userInfo)
        coreDispatch({type: 'userinfo', payload: userInfo});
        coreDispatch({type: 'isActive', payload: true});
        route('/dashboard')
    }

    return (
        <section class="bg-white margin-auto">
            <section class="padding-large">
                <div class="column margin-x-auto margin-y-1 col-1-3">
                    <h1 class="align-center padding-y-1 display1 -extra-lite">Almost there!</h1>
                    <p class="align-center padding-medium display4-lite">{helperText}</p>
                    <RegistrationForm handler={handleSubmit} refs={refMap}/>
                </div>
            </section>
        </section>
    )
};


const RegistrationForm = ({ handler, refs }) => {

    return (
        <form onSubmit={handler}>
            <div class="input_container">
                <input type="text" autofocus ref={refs.first} required />
                <label>First Name: </label>
            </div>
            <div class="input_container">
                <input type="text" ref={refs.last} required/>
                <label>Last Name: </label>
            </div>
            <div class="input_container">
                <input type="tel" ref={refs.phone} required />
                <label>Phone: </label>
            </div>
            <div class="row margin-y-1">
                <button style="background: #01A7E1; border: none; width: 100%; color: white; padding: 18px; font-size: 18px; font-weight: 600;" type="submit">
                    Submit
                </button>
            </div>
        </form>
    )
}


const parsePhoneToDigitsOnly = str => {
    return [...str].filter(char => char >= '0' && char <= '9').join('')
}