import { Router } from "preact-router";
import { Link } from "preact-router/match";
import { useContext, useEffect, useLayoutEffect, useState } from "preact/hooks";
import { CoreState } from "../../../Utilities/contextLibrary";
import api from "../../../Utilities/API/api";

import { Nav } from "../../../components/Account/Nav";

import { AccountOverview } from "../../../components/Account/Overview";
import { AccountPreferences } from "../../../components/Account/Settings";
import { BillingHistory } from "../../../components/Account/History";
import { BillingInfo } from "../../../components/Account/Info";
import { Payments } from "../../../components/Account/Payment";

import PaymentMethod from "../../../components/Account/PaymentMethod";
import HistoryItems from "../../../components/Account/HistoryItems";

const navItems = [
  {
    label: "Account Overview",
    href: "/dashboard/account",
  },
  {
    label: "Account Settings",
    href: "/dashboard/account/preferences",
  },
  {
    label: "Billing History",
    href: "/dashboard/account/billing",
  },
  {
    label: "Billing Info",
    href: "/dashboard/account/billinginfo",
  },
  {
    label: "Payment Methods",
    href: "/dashboard/account/payment-methods",
  },
];

const Account = () => {
  const { coreState, coreDispatch } = useContext(CoreState);
  const [subscription, setSubscription] = useState(null);
  const [employee, setEmployee] = useState();
  const [customer, setCustomer] = useState();
  const [devices, setDevices] = useState();
  const [orgInfo, setOrgInfo] = useState();
  const [payments, setPayments] = useState();
  const [invoices, setInvoices] = useState([]);
  const [infoState, infoDispatch] = useState();

  const verifyRoute = async (event) => {
    // console.log('event: ', event.current);
    if (event.previous == "/dashboard/account/payment") {
      window.location.reload();
    }
  };
  const grabPDF = async (url) => {
    try {
      const pdf = await fetch(url, { method: "GET", redirect: "follow" });
      if (pdf.status >= 200 && pdf.status <= 299) {
        const blob = await pdf.blob();
        // console.log('blob: ', blob);
        const blobHope = new Blob([blob], { type: "application/pdf" });
        console.log("blobHope: ", blobHope);
        const laughs = window.URL.createObjectURL(blobHope);
        console.log(laughs);
        return laughs;
      } else {
        throw Error(pdf.status);
      }
    } catch (e) {
      console.log("Failed to create PDF blob: ", e);
      return url;
    }
  };

  useEffect(() => {
    api.get(coreState.tokens.id_token, "customer/devices").then((res) => {
      console.log("devices= ", res);
      setDevices(res.response);
    });
    api.get(coreState.tokens.id_token, "customer/subscription").then((res) => {
      console.log("subscription= ", res);
      setSubscription(res.response);
    });
    api.get(coreState.tokens.id_token, "billing/methods").then((res) => {
      console.log("payments= ", res);
      setPayments(res.response);
    });
    api.get(coreState.tokens.id_token, "organization/info").then((res) => {
      console.log("billing info= ", res);
      setOrgInfo(res.response);
    });
    api.get(coreState.tokens.id_token, "billing/invoices")
      .then(res => {
        setInvoices(res.response.map(item => {
          return {
            date: item.date,
            balance: convertDollars.format(item.balance /= 100),
            coupons: item.coupon,
            number: item.number,
            view: item.url,
            url: item.url
          }
        }));
      })
      // console.log("invoices: ", res);

      // res.response.forEach(async (item) => {
        
      // });
    // });
    api.get(coreState.tokens.id_token, "customer/").then((res) => {
      console.log("customer= ", res);
      setCustomer(res.response);
    });
    if (coreState.role == "owner") {
      api.get(coreState.tokens.id_token, "employee/all").then((res) => {
        if (res.status == 500) {
          console.log("res.response: ", res.response);
          setEmployee([]);
        } else {
          console.log("employee= ", res);
          setEmployee(res.response);
        }
      });
    }
  }, ["redundancyGate"]);

  useLayoutEffect(() => {
    if (invoices) {
      console.log("invoices: ", invoices);
    }
  }, [invoices]);
  return (
    <section class="bg-white padding-large">
      <div class="row">
            <span class="col-1-4 column">
              <h3 class="display3a -lite account-overview-nav--desktop-title">
                My Account
              </h3>
              <nav class="column margin-y-2 account-overview-nav--desktop">
                {coreState.customer.CLIENTSTATUS.includes("employee.owner") && (
                  <Link
                    class="link margin-y-1"
                    activeClassName="link-active"
                    href="/dashboard/account"
                  >
                    Account Overview
                  </Link>
                )}
                <Link
                  class="link margin-y-1"
                  activeClassName="link-active"
                  href="/dashboard/account/preferences"
                >
                  Account Settings
                </Link>
                {coreState.customer.CLIENTSTATUS.includes("employee.owner") && (
                  <Link
                    class="link margin-y-1"
                    activeClassName="link-active"
                    href="/dashboard/account/billing"
                  >
                    Billing History
                  </Link>
                )}
                {/* <Link class="link margin-y-1" activeClassName="link-active" href="/dashboard/account/payments">Payment History</Link> */}
                {/* {coreState.customer.CLIENTSTATUS.includes("employee.owner") && (
                  <Link
                    class="link margin-y-1"
                    activeClassName="link-active"
                    href="/dashboard/account/billinginfo"
                  >
                    Organization Info
                  </Link>
                )} */}
                {coreState.customer.CLIENTSTATUS.includes("employee.owner") && (
                  <Link
                    class="link margin-y-1"
                    activeClassName="link-active"
                    href="/dashboard/account/payment-methods"
                  >
                    Payment Methods
                  </Link>
                )}
                {/* <Link class="link margin-y-1" activeClassName="link-active" href="/dashboard/account/notifications">Notification Settings</Link> */}
              </nav>

              {/* mobile nav */}
              <div class="account-overview-nav--mobile">
                <Nav items={navItems} />
              </div>
            </span>

            <span class="col-3-4" style="flex-grow: 3;">
              <Router onChange={verifyRoute}>
                <AccountOverview
                  props={{
                    subscription,
                    payments,
                    customer,
                    employee,
                    devices,
                    coreState,
                  }}
                  path="/dashboard/account/:rest*"
                />
                <AccountPreferences
                  props={{ coreState, coreDispatch }}
                  path="/dashboard/account/preferences"
                />
                <HistoryItems
                  props={{ invoices }}
                  path="/dashboard/account/billing"
                />
                <BillingInfo
                  props={{ orgInfo, coreState }}
                  path="/dashboard/account/billinginfo"
                />
                <Payments
                  path="/dashboard/account/payment-methods"
                  props={{ customer, payments, subscription, coreState }}
                />
              </Router>
            </span>
      </div>
    </section>
  );
};

const convertDollars = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export default Account;
