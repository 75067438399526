/* eslint-disable no-unused-vars */
import { h } from "preact";
import { useEffect } from "preact/hooks";
import "../../style/components/blocks/payment_method_card.css";

import logoAmex from "../../assets/images/icons/logo-amex.png";
import logoMastercard from "../../assets/images/icons/logo-mastercard.png";
import logoVisa from "../../assets/images/icons/logo-visa.png";
import { useToggle } from "../../Utilities/useToggle";

export default function PaymentMethodCard(props) {
  const { brand, last4, name, exp } = props;

  // TODO: "isDefault" will be a state managed somewhere else
  // so isDefault, and setIsDefault should be from some context provider
  const [isDefault, setIsDefault] = useToggle();

  const getIcon = () => {
    switch (brand) {
      case "amex":
        return logoAmex;
      case "mastercard":
        return logoMastercard;
      case "visa":
        return logoVisa;
      default:
        break;
    }
  };
  useEffect(() => {
   console.log(props);
  }, [props])
  
  return (
    <div
      className={`payment-method-card ${
        isDefault ? "payment-method-card--default" : ""
      }`}
      {...props}
    >
      <div className="payment-method-card__heading">
        <div className="payment-method-card__icon">
          <img src={getIcon()} alt={brand} />
        </div>
{/* 
        <div className="payment-method-card__options">
          <div className="payment-method-card__option-default">
            <input
              type="checkbox"
              name="default"
              id="default"
              className="payment-method-card__checkbox"
              onChange={(e) => setIsDefault()}
            />
            <label
              className="payment-method-card__checkbox-label"
              for="default"
            >
              Default
            </label>
          </div>

          <div className="payment-method-card__option-delete">
            <button className="payment-method-card__delete-btn">Delete</button>
          </div>
        </div> */}
      </div>

      <div className="payment-method-card__account-number">
        <span className="payment-method-card__number">
          Card ending in {last4}
        </span>
      </div>

      <div className="payment-method-card__footer">
        <span className="payment-method-card__name">{name}</span>
        <span className="payment-method-card__expiration">{exp.month}/{exp.year}</span>
      </div>
    </div>
  );
}
