import { h } from "preact";
import api from "../../Utilities/API/api";

export const UpdateSubscription = ({ parent }) => {
  const { deviceNum, coreState, toggleSubModal } = parent;
  console.log("deviceNum: ", deviceNum);
  const devices = deviceNum;
  const updateDevices = (e) => {
    e.preventDefault();
    api
      .put(coreState.tokens.id_token, "customer/subscription", {
        licenses: deviceNum,
      })
      .then((res) => {
        console.log("Device update response: ", res.response);
        window.location.reload();
      });
  };

  return (
    <section
      style="background: rgba(0, 0, 0, 0.6); 
              width: 100%; 
              height: 100%; 
              position: fixed; 
              top: 0; 
              left: 0;
              display: flex;
              align-items: center;"
    >
      <div
        style="z-index: 1;
                      max-width: 360px;
                      align-items: center;
                      background: var(--brand-white);
                      margin: auto;
                      min-width: 40%;
                      padding: 20px;"
      >
        <img
          onClick={toggleSubModal}
          style="filter: brightness(0%); position: relative; top: -3%; left: 90%;"
          src="/assets/images/icons/icon-close.svg"
        />
        <h2 class="display2 -extra-lite align-center margin-y-1">
          Update Subscription
        </h2>
        <h4 class="display4 -extra-lite align-center margin-y-1">
          Based on your new selection your monthly subscription will increase to
          ${devices * 14.99}/mo from starting on your next billing cycle.
        </h4>
        <div class="align-center">
          <button onCLick={updateDevices} class="button -blue" type="submit">
            Update Subscription
          </button>
        </div>
      </div>
    </section>
  );
};
