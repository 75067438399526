import {h} from 'preact';
import { CoreState } from '../../Utilities/contextLibrary';

import { useEffect, useState, useContext, useRef } from 'preact/hooks';
import api from '../../Utilities/API/api';
import { prismicBlogs } from '../../Utilities/prismic/prismicClient';

import Block from '../../components/CTA Block/cta_block'
import Included from '../../components/Included/included';
import authWrapper from '../../Utilities/authGarage/authGarage';

import LogRocket from 'logrocket';
import { route } from 'preact-router';

const Benefit = () => {
	const [doc, setDocData] = useState();
	
	const { coreState, coreDispatch } = useContext(CoreState);
	
	var client = {};
	const [ user, setUser ] = useState();

	useEffect( async () => {
		// console.log('coreState: ', coreState);
		if (coreState.userinfo?.email.split('@')[1] == 'us.aflac.com') {
			route('/');
		}
		prismicBlogs('benefit').then(res => {
			// console.log('doc: ', res.results[0].data);
			setDocData(res.results[0].data)
		})
		api.get(
			coreState.tokens.id_token,
			'customer/',
			'apiv5'
		).then(res => {
			console.log('customer: ', res);
			client = res.response
			console.log('client: ', client);
		});
		setUser(coreState.userinfo || {});	
	}, [client]);
	// console.log('client: ', client);
	const infoObject = {
		name: {
			first: coreState.userinfo?.given_name,
			last: coreState.userinfo?.family_name
		},
		email: coreState.userinfo?.email,
		phone: '',
		adress: {
			line1: 'benefit',
			line2: 'benefit',
			city: 'benefit',
			state: 'benefit',
			postal_code: 'benefit'
		},
		metadata: {
			status: 'Benefit Claimed'
		}
	}
	const createCustomer = () => {
		api.post(coreState.tokens.id_token, 'customer/', {
			name: {
				first: infoObject.name.first,
				last: infoObject.name.last
			},
			email: infoObject.email,
			phone: infoObject.phone,
			address: infoObject.adress,
			metadata: {
				status: 'Benefit Claimed'
			}
		}).then(res => {
			// console.log('res: ', res);
			LogRocket.track('Keeper')
			if ('message' in res) {
				window.alert(res.message);
				window.location.reload();
			} else {
				api.get(coreState.tokens.id_token, 'customer/', 'apiv5').then(res => {
					window.location.assign(res.services.keeper.vault_url);
				})
			}
		})
	}
	const handleClick = () => {
		if (!client.keeper) {
			console.log('Customer Doesn\'t exist: ');
			createCustomer();
		} else {
			LogRocket.track('Keeper')
			window.location.assign(client.keeper.vault_url);
		}
	}

	return (
		doc ? (
			<>
				<section 
					id="we-protect-your-business" 
					class={`-benefit hero`} 
					style={`--hero-image:url(${doc.hero_bg.url})`}
				>
					<h2 class={`title -title display1`}>{doc.hero_title[0].text}</h2>
					<p class={`summary -summary body`}>{doc.hero_copy[0].text}</p>
					<p class={`action -action`}>
						{	client.keeper ? (
								<a 
									id="keeper_link" 
									// href="#"
									href={client.keeper.vault_url} 
									class={`button -blue`}
								>
									<img src="/assets/images/keeper.png" />
									Activate Keeper
								</a> 
							) : (
								<a id="keeper_link" onClick={handleClick} class={`button -blue`}>
									<img src="/assets/images/keeper.png" />
									Register for Keeper
								</a> 
							)
						}
					</p>
				</section>
				<Block content={doc}/>
				{/* <Included content={doc}/> */}
			</>
		) : null
	)
}
export default Benefit;