import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const analytics = Analytics({
	app: 'Aflac Cyber Protection',
	plugins: [googleAnalytics({
		trackingId: 'UA-194527395-1'
	})]
});

export default analytics;
