const { default: api } = require("./API/api");

const getUserRoute = async (destination, token, dispatcher, flow) => {

    try {
        await api.get(token, 'customer/').then(client => {
            if ('message' in client.response) return '/logout';
            
            console.log('REQUESTING CLIENT: ', client.response)
            console.log('REQUESTED FLOW: ', flow)
            console.log('REQUESTED DESTINATION', destination);
            console.log("CLIENT STATUS: ", client.response.CLIENTSTATUS);
            // if (destination?.includes('/employee/register')) return destination
            // If the user is attempting to visit the benefit page, set the header_view to 'benefit' and allow them through.
            if (destination == '/benefit') {
                dispatcher({ type: 'header_view', payload: 'benefit'});
                return '/benefit'
            }
            // If the customer's client status is active set coreState.isActive boolean to true and route them to the dashboard.
            if (client.response.CLIENTSTATUS == "active") {
                dispatcher({ type: 'isActive', payload: true});
                return '/dashboard'
            }
            
            if (client.response.CLIENTSTATUS == 'register') {
                if (client.response.CLIENTTYPE == 'locked') return '/logout'
                console.log("Routing to registration");
                // If client status is register, set coreState.isActive boolean to false
                // dispatcher({ type: 'isActive', payload: false});
                if (['associate', 'locked', 'owner'].includes(client.response.CLIENTTYPE)) {
                    console.log('On to Register');
                    return '/register'
                }
    
                return '/employee/register'
            }
            
            if (client.response.CLIENTSTATUS == 'locked') { return '/broker_validation?stage=failed' }
            return '/register'

        })


    } catch (e) {
        console.log('Error in ROUTEUSER Logic: ', e);
        window.alert('looks like we hit a snag, if this is your first time seeing this message please refresh the page and try again, otherwise please contact support for further assistance.');
        window.location.reload();
    }
}

module.exports.getUserRoute = getUserRoute