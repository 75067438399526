import { route } from 'preact-router';
import authWrapper from '../authGarage/authGarage';


export default async function verifyRoute(event, coreState, coreDispatch) {
    console.log('coreState: ', coreState);
    const {	current, previous, router, url } = event;
    if (!current) {
        console.log("Current Path: ",current);
        route('/not_found');
    } else {
        console.log('Path: ', current.props.path);
        coreDispatch({ type: 'header_view', payload: current.props.path })
        const safe = Boolean(
            (Math.floor(Date.now() / 1000)) <
            (coreState.tokens.expiration - 10)
        );
        console.log('Token is safe: ', safe);
        if (coreState.isAuth && !safe)  {
            try {
                authWrapper.refresh(coreState.tokens.refresh_token).then(res => {
                    const { response, status } = res;
                    if (status !== 200) {
                        // throw new Error ('Error in token refresh', response);
                        console.log('Error in token refresh', response);
                        route('/logout');
                    } else {
                        const refreshResponse = {
                            refresh_token: coreState.tokens.refresh_token,
                            access_token: response.AuthenticationResult.AccessToken,
                            id_token: response.AuthenticationResult.IdToken,
                            expiration: Math.floor(Date.now() / 1000) + response.AuthenticationResult.ExpiresIn
                        }
                        coreDispatch({type: 'tokens', payload: refreshResponse})
                    }
                })

            } catch (error) {
                console.log('Error in token refresh', error);
                route('/logout');
            }
        }

        if (current.props.auth) {
            console.log('Auth: ', current.props.auth);
            const maintApproved = [
                'krand@aflac.com',
                'krand@aflacqa.com',
                'ehindman@aflac.com',
                'cbaltes@aflac.com',
                'tgalang@aflac.com',
                'dbailey@aflac.com',
                'eric.the.hindman@gmail.com',
                'john@thumpermountain.com',
                'jane@thumpermountain.com',
                'tgalang84@gmail.com',
                'krand40@gmail.com',
                'ashleybrooks997@gmail.com'
            ]
            if (!coreState.isAuth) {
                window.localStorage.setItem('preact:authDest', url);
                console.log('Not Authenticated');
                if(url.includes('/benefit')) {
                    const handshake = await authWrapper.generate();
                    const { pkce, challenge } = handshake;
                    coreDispatch({ type: 'handshake', payload: handshake });
                    coreDispatch({ type: 'authPhase', payload: 'receive' });
                    const provider = 'Aflac';
                    authWrapper.initiate({ pkce, challenge, provider });
                }
                return route('/login');

            }
            // else {
            // 	if (maintApproved.includes(coreState.userinfo?.email.toLowerCase())) {
            // 		console.log('emial included in list: ', coreState.userinfo);
            // 	} else {
            // 		console.log('email not included in list: ', coreState.userinfo);
            // 		route('/maintenance');
            // 	}
            // }
            if (coreState.customer?.maintenance?.accountLocked) { route('/maintenance'); return; }
        }
        if (current.props.role) {
            current.props.role.includes(coreState.role) ? null : route('/dashboard');
        }
    }
};
