// Application state
import { CoreState } from '../../Utilities/contextLibrary';
// Hooks
import { useEffect, useState, useContext, useRef, useLayoutEffect } from 'preact/hooks';
// Utility Functions
import { prismicClient } from '../../Utilities/prismic/prismicClient';
import api from '../../Utilities/API/api';
import { route } from 'preact-router';
// Functional components
import Devices from '../../components/Devices/devices';
import Issues from '../../components/Issues';
import Loader from '../../components/Loading/loading';
import Software from '../../components/Resources/software';
import authWrapper from '../../Utilities/authGarage/authGarage';

const Dashboard = () => {

	const isDocReady = useRef(false);
	const [ doc, setDocData ] = useState(null);
	const [ bannerDoc, setBannerDocData ] = useState(null);
	const { coreState, coreDispatch } = useContext(CoreState);
	const [ devices, setDevices ] = useState([])
	const [ issues, setIssues ] = useState([])
	const [ customer, setCustomer ] = useState()
	const [ user, setUser ] = useState({})
	const [ subscription, setSubscription ] = useState(null);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async() => {
		prismicClient('dashboard', 'dashboard').then(res => {
			isDocReady.current = true;
			setDocData(res.data);
		});
		prismicClient('cybersecurity-guide', 'cybersecurity-guide').then(res => {
			console.log('banner', res);
			isDocReady.current = true;
			setBannerDocData(res.data);
		});
		api.get(coreState.tokens.id_token, 'customer/devices', 'apiv5').then(res => setDevices(res.response))
		if (['owner', 'associate'].includes(coreState.role)) {api.get(coreState.tokens.id_token, 'customer/subscription', 'apiv5').then(res => setSubscription(res.response))}
		api.get(coreState.tokens.id_token, 'customer/alerts', 'apiv5').then(res => setIssues(res.response))
		api.get(coreState.tokens.id_token, 'customer/', 'apiv5').then(res => {
			if (res.status === 401) {
				authWrapper.refresh(coreState.tokens.refresh_token).then(res => {
					const { response, status } = res;
					if (status !== 200) {
						console.log('refresh token failed');
						return route('/logout');
					}
						let refreshResponse = {
                            refresh_token: coreState.tokens.refresh_token,
                            access_token: response.AuthenticationResult.AccessToken,
                            id_token: response.AuthenticationResult.IdToken,
                            expiration: Math.floor(Date.now() / 1000) + response.AuthenticationResult.ExpiresIn
                        }
                        coreDispatch({type: 'tokens', payload: refreshResponse})

				})

			}
			if (res.status !== 200) return alert(res.response.message).then(window.location.reload());
			console.log('customer api response: ', res.response);
			coreDispatch({type: 'customer', payload: res.response});
			coreDispatch({type: 'role', payload: res.response.CLIENTSTATUS});
			coreDispatch({type: 'isActive', payload: true});
			setCustomer(res.response);
			if (!res.response.CLIENTTYPE.includes('employee' || 'admin')) return
			doc?.included_items?.split(2,1);
		})
		setUser(coreState.userinfo)
	}, [coreDispatch, coreState.role, coreState.tokens, coreState.userinfo, doc?.included_items]);
	if (!doc || !customer || !bannerDoc) return;
	return (
		<>
			{ !isDocReady && !customer ? <Loader /> : (
				<>
							<section class="bg-white padding-large">
								<div class="row padding-medium space-between">
									<div class="row | padding-medium | space-between | user-head">
										<span class="user-info">
											<h2 class="display2 -extra-lite">Hello, {user?.given_name}</h2>
											{customer?.organization && <h4 class="display4a -extra-lite">{customer?.CLIENTTYPE} of {customer?.organization}</h4>}
										</span>
									</div>
									<div class="row space-between" style="height: min-content;">
										{devices.length > 0 && <Software doc={doc} />}
									</div>
								</div>
								{
									(devices.length > 0) ?
										<div class="row space-between">
											<span class="col-1-3">
												<h3 class="display3b -lite">{subscription?.consumption} of {subscription?.licenses} Devices Used</h3>
												<Devices devices={devices} />
											</span>
											<span class="col-2-4">
											{issues?.length == 0 ? null : <h3 class="display3b -lite">{issues?.length} open issue{issues?.length > 1 ? 's' : null}</h3>}
                      {issues ? <Issues issues={issues} /> :  null}
											</span>
										</div>
									:
										<NoDevices doc={doc} subscription={subscription} customer={customer} />
								}
								<IncludedServices doc={doc} customer={customer} />
								{customer?.REFCODE && <ReferralBlock doc={doc} customer={customer} />}
							</section>
				</>
			)
			}
		</>
	);
};

const NoDevices = (props) => {
	const { doc, subscription, customer } = props
	const [installVersion, setInstallVersion] = useState([])
	useLayoutEffect(() => {
		if (customer.access) {
			// console.log('customer.access', customer.access);
			setInstallVersion([
				{
					label: 'Windows',
					value: customer.access?.prefix + 'AflacCyberProtection.exe' + customer.access?.suffix || doc.windows_installer.url
				},
				{
					label: 'Mac OS',
					value: customer.access?.prefix + 'AflacCyberProtection.zip' + customer.access?.suffix || doc.mac_installer.url
				}
			]);
		}
	}, [customer, doc]);
	const [ downloadLink, setDownloadLink ] = useState();
	const handleChange = (e) => { setDownloadLink(e.target.value) };
	if (installVersion.length < 2) return null
	return (
		<section class="row | bg-lighter-blue | margin-y-2 | margin-x-1 | padding-medium | justify-center">
			<div class="column | col-1-3 | justify-center | margin-x-auto | justify-center">
				<h3 class="display3a | -lite |	 margin-y-1 | align-center">Let's Get Started</h3>
				<p class="margin-y-1 | align-center">{doc?.install_section_text[0].text.split('-')[0]} {subscription?.licenses} {doc?.install_section_text[0].text.split('-')[1]}</p>
				<div class="input_container col-1-2 margin-y-1">
						<select value={downloadLink} onChange={handleChange} class={`software`}>
							<option value="">Select Platform</option>
							{installVersion?.map((option) => (
								<option key={option} value={option.value}>{option.label}</option>
							))}
						</select>
						<label for="platform">Select Platform</label>
					</div>
					<a class={`button -blue margin-y-1`} download target="_blank" href={downloadLink} rel="noreferrer">Download</a>
			</div>
		</section>
	)
}

const IncludedServices = ({ customer, doc }) => {
	return (
		<section class="included | column | margin-y-1">
			<h3 class="display3a | -lite | margin-y-1 | align-center">{doc?.included_title[0].text}</h3>
			<div class="row">
				{
					doc?.included_items.map(item =>
							<span key={item} class="padding-medium column col-1-4 space-between">
								<div style="min-height: 40px;">
									<img class="logo margin-y-1"  src={item.included_image.url} />
								</div>
								<h4 class="display4">{item.included_item_title[0].text}</h4>
								<p style="color: var(--brand-grey)">{item.included_item_label[0].text}</p>
								<p class="margin-y-1">{item.included_item_description[0].text}</p>
								<a class="button -transparent margin-y-1" style="--button-color-b: black;" href={(item.included_item_link.url.includes('keeper')) ? (customer?.services?.keeper.vault_url || item.included_item_link.url) : (item.included_item_link.url)} target="_blank" rel="noreferrer">{item.included_item_link_label[0].text}</a>
							</span>
					)
				}
				<span class="bg-grey col-1-4 padding-32">
					<h4 class="display4-lite">Got questions? <br />Support is included.</h4>
					<p class="margin-y-1">Whether you need technical support or would just like to learn more about cyber security, we're here to answer your questions.</p>
					<p class="margin-y-1" style="color: var(--brand-charcoal); font-weight: 700;">Monday - Friday: 7am - 7pm</p>
					<a class="" href="tel:+18442042206"><img src="/assets/images/icons/phone.svg" style="margin: 10px 5px 10px 0px;" />(844) 204 - 2206</a>
					<br />
					<a class="" href="mailto:cyberprotection@aflac.com"><img src="/assets/images/icons/mail.svg" style="margin: 10px 5px 10px 0px;" />cyberprotection@aflac.com</a>
				</span>
			</div>
		</section>
	)
}
const ReferralBlock = ({ customer, doc }) => {
	const copyCode = () => {
		console.log("Customer Referal Code", customer.REFCODE);
		navigator.clipboard.writeText(customer.REFCODE);
	}
	const shareCode = e => {
		console.log('event: ', e);
		return e;
	}
	// const { customer, doc } = props;
	// console.log('doc: ', doc);
	// console.log('customer.REFCODE: ', customer?.REFCODE);
	return (
		<section class="row referral margin-y-2" style={`--background-image: url('${doc?.referral_background_image.url}')`}>
			<div class="col-2-4" style="color: var(--brand-white); margin: 5%">
				<h2 class="display2">{doc?.referral_title[0].text}</h2>
				<p class="margin-y-1" style="color: var(--brand-white);">{doc?.referral_description[0].text}</p>
				<p style="color: var(--brand-white);">Your Referral Code</p>
				<h3 style="padding: 16px; background-color: rgba(0, 0, 0, 0.6); max-width: 340px;">{customer?.REFCODE}</h3>
				<div class="margin-y-1 flex">
					<button onClick={copyCode} class="button -orange" style="margin-right: 16px; width: 162px;"><img src="/assets/images/icons/icon-copy.svg" style="margin-right: 5px;" />Copy Code</button>
					{/* <button onClick={shareCode} class="button" style="width: 162px; border: 1px solid var(--brand-white);"><img src="/assets/images/icons/icon-share.svg" style="margin-right: 5px;" />Share</button> */}
				</div>
			</div>
			<div class="col-2-4" />
		</section>
	)
}

export default Dashboard;
