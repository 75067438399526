import { useContext } from "preact/hooks";
import { CoreState } from "../../Utilities/contextLibrary";
import { getUserRoute } from "../../Utilities/routing";
import Loader from "../../components/Loading/loading";
import { route } from "preact-router";
import { captureException } from "logrocket";

const RouteUser = () => {
    const { coreState, coreDispatch } = useContext(CoreState);
    const dest = window.localStorage.getItem('preact:authDest');
    console.log('dest: ', dest);
    
    route(
        getUserRoute(
            dest,
            coreState.customer,
            coreDispatch,
            coreState.account_type
        )
    )

    return (
        <section class="bg-white margin-x-auto">
			<h1>'Verifying Account Details...' </h1>
				<Loader/>
		</section>
    )
}

export default RouteUser;