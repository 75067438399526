import {h} from 'preact';

const Devices = ({ devices }) => {
	// console.log('devices: ', devices);
	return (
		<div class="">
			{ devices?.length > 0 ? (
					<ul class="devices">
						<li><span class="platform">Device</span><span class="host">Platform</span></li>
						{devices.map( device => <DeviceItem device={device} /> )}
					</ul>
				) : (
					<p>Download the Aflac Cyber Protection Installer on each of your supported devices.</p>
				)
			}
		</div>
	)
}
const DeviceItem = ({ device }) => (
	<li class="margin-y-1" style={device.hasAlerts ? '--status: var(--brand-light-orange' : '--status: var(--brand-light-grey'}>
		<span class="">{device.host}</span>
		<span class="">{
			device.platform.includes('windows') ? (<span>Windows <img src="/assets/images/icons/os-ms.svg"/></span>)
			: device.platform.includes('osx') ? (<span>Mac OS <img src="/assets/images/icons/os-osx.svg"/></span>)
			: (<></>)}</span>
	</li>
)

export default Devices;