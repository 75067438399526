import { h } from "preact";

export const BillingHistory = ({ props }) => {
  const formatDate = (date) => {
    if (!date) return "";
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date(date * 1000));
  };
  const { invoices } = props;
  return (
    <section class="margin-y-1 ">
      <h2 class="display2 -extra-lite">Invoices</h2>
      <span>
        <div class=" flex space-between align-left">
          <span>Date</span>
          <span>Invoice #</span>
          <span>Coupons Applied</span>
          <span>Total</span>
        </div>
        {invoices.map(
          ({ href, settled, total, invoice_number, coupons }, index) => {
            console.log("href: ", href);
            console.log("settled: ", settled);
            console.log("total: ", total);
            console.log("invoice_number: ", invoice_number);
            console.log("coupons: ", coupons);
            const handleOpenPDF = () => {
              window.location.href = href;
            };
            return (
              <div
                key={index}
                href="#"
                class="flex space-between align-left"
                onClick={handleOpenPDF}
              >
                {/* <a href={href} target="_blank" native> */}
                <span>{formatDate(settled)}</span>
                <span>{invoice_number}</span>
                <span>{coupons}</span>
                <span>{total.toFixed(2)}</span>
                {/* </a> */}
              </div>
            );
          }
        )}
      </span>
    </section>
  );
};
