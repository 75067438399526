import { h } from "preact";
import {
  prismicClient,
  prismicBlogs,
} from "../../Utilities/prismic/prismicClient";

import { useEffect, useState, useRef } from "preact/hooks";
import Hero from "../../components/Hero/hero";
import Block from "../../components/CTA Block/cta_block";
import Included from "../../components/Included/included";
import VideoBlock from "../../components/Video Block/video";
import FAQ from "../../components/FAQ/faq";
import BlogSection from "../../components/Blog Section/blog_section";
import Pricing from "../../components/CTA Block/pricing_block";
import Loader from "../../components/Loading/loading";

import ACPComparison from "../../components/ACPComparison";
import IncludedLogos from "../../components/Included/home_logos";

const Home = () => {
  const [doc, setDocData] = useState(null);
  const [blog, setBlogData] = useState(null);

  useEffect(async () => {
    prismicClient("homepage", "homepage").then((res) => {
      setDocData(res.data);
    });
    prismicBlogs("blog_post").then((res) => {
      setBlogData(res.results);
    });
  }, ["redundancyGate"]);

  return doc && blog ? (
    <div className="home-page">
      <Hero content={doc} />

      <ACPComparison
        image={doc.backgroundimage.url}
        title={doc.heading[0].text}
        description={doc.description}
        logo={doc.logo.url}
        items={doc.items}
      />
      <VideoBlock content={doc} />

      <IncludedLogos
        title={doc.heading1[0].text}
        logos={doc.logos}
        textBlocks={doc.textblocks}
      />

      {/* <Block content={doc} />
      <Included content={doc} /> */}

      <Pricing content={doc} />

      <FAQ content={doc} />

      <BlogSection blogs={blog} content={doc} />
    </div>
  ) : (
    <Loader />
  );
};

export default Home;
