import { h } from "preact";

import icon from "../../assets/images/CMYK_4C_pos_Aflac_Logo_Icon_Circle.png";

const Footer = () => (
  <footer class={`footer`}>
    <div>
      <span>© 2023 AFLAC INCORPORATED</span>
      <span>
        <a href="https://www.aflac.com/about-aflac/privacy-policy.aspx" native>
          Privacy Policy & Notifications
        </a>
        <a href="https://www.aflac.com/about-aflac/terms-of-use.aspx" native>
          Terms of Use
        </a>
      </span>
    </div>
    <div>
      <span>
        <img class={`logo`} href="/" src={icon} alt="" role="presentation" />
      </span>
    </div>
    <div>
      <p>
        “Aflac” and the Aflac Logo are registered trademarks of American Family
        Life Assurance Company. All rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
