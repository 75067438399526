import { h, createContext } from 'preact';
import UserType from '../../../components/User Type/user_type';
import CredentialEntry from '../../../components/Credential Entry/credential_entry';
import EmailConfirmation from '../../../components/Email Confirmation/email_confirmation';
import CreateAuth from '../../../components/Create Auth/create_auth';
import TOTPMFAConfigure from '../../../components/TOTP/totp_config';
import TOTPMFAEntry from '../../../components/TOTP/totp_entry';
import RouteUser from '../../../components/Route User/route-user';
import { useState } from 'preact/hooks';


const Login = ({ callback }) => {
    const AuthenticationContext = createContext()
    const urlParams = new URLSearchParams(window.location.search);

    const [ authState, setAuthState ] = useState({
        stage: urlParams.get('stage') || 'account-type',
        user: {
            email: urlParams.get('email') || 'None',
        }
    })

    return (
        <section class="bg-white margin-auto">
            <AuthenticationContext.Provider value={{authState, setAuthState, callback}}>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js" />

                {authState.stage == 'account-type' ? <UserType context={AuthenticationContext} /> : null}
                {authState.stage == 'login' ? <CredentialEntry context={AuthenticationContext} /> : null}
                {authState.stage == 'sign-up' ? <CreateAuth context={AuthenticationContext} /> : null}
                {authState.stage == 'confirm-email' ? <EmailConfirmation context={AuthenticationContext} /> : null}
                {authState.stage == 'mfa-setup' ? <TOTPMFAConfigure context={AuthenticationContext} /> : null}
                {authState.stage == 'token-mfa' ? <TOTPMFAEntry context={AuthenticationContext} /> : null}
                {authState.stage == 'route-user' ? <RouteUser context={AuthenticationContext} /> : null}
            </AuthenticationContext.Provider>
        </section>
    )
}
export default Login;
