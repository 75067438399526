import { h } from 'preact';

const VideoBlock = ({ content }) => {
	const videoAccountId = content.video_link.url.split("/")[3]
	const videoId = content.video_link.url.split("=")[1]
	return (
		<section id="protection" class={`video protection`}>
		<section class="margin-auto">
			<h2 class={`-title display3c`}>
				{content.video_title[0].text}
			</h2>
			<p class={`-summary body`}>
				{content.video_copy[0].text}
			</p>
			<p class={`-summary -link`}>
				<a href="/register?plan=pro" class={`button -blue`}>
					{content.video_link_label[0].text}
				</a>
			</p>
		</section>
		<section class="video video__player">
			<video data-video-id={videoId}
				data-account={videoAccountId}
				data-player="default" 
				data-embed="default" 
				data-application-id 
				class="video-js" 
				controls
				style="position: relative;  width: 100%; height: auto;"></video>
		</section>
	<script src="//players.brightcove.net/2112711598001/default_default/index.min.js"></script>
	</section>
)
};

export default VideoBlock;
