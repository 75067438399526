import authConfig from './native.config';

class AuthDirect {
    constructor (configObject) {
        this.config = configObject;
    };
    decodeToken = token => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
    parseAuthenticationResult = authResult => {
        return {
            refresh_token: authResult.RefreshToken,
            access_token: authResult.AccessToken,
            id_token: authResult.IdToken,
            expiration: Math.floor(Date.now() / 1000) + authResult.ExpiresIn
        }
    }

    // Kicks off a native sign up flow against the Cognito API
    signup = async ({ username, password, attributes }) => {
        return await this.handler(
            'SignUp',
            {
                Username: username,
                Password: password,
                UserAttributes: Object.keys(attributes).map(item => {
                    return { Name: item, Value: attributes[item] }
                })
            },
            'POST'
        );

        // Exceptions:
    };

    // Kicks off a native sign up flow against the Cognito API
    confirmSignup = async ({ username, code }) => {
        return await this.handler(
            'ConfirmSignUp',
            {
                Username: username,
                ConfirmationCode: code
            },
            'POST'
        );

        // Exceptions:
    };
    // Kicks off a native authentication flow against the Cognito API
    login = async ({ username, password }) => {
        return await this.handler(
            'InitiateAuth',
            {
                AuthFlow: 'USER_PASSWORD_AUTH',
                AuthParameters: {
                    USERNAME: username,
                    PASSWORD: password
                }
            },
            'POST'
        );
    };

    // Kicks off a native authentication flow against the Cognito API
    changePassword = async ({ accessToken , previous, proposed }) => {
        return await this.handler(
            'ChangePassword',
            {
                AccessToken: accessToken,
                PreviousPassword: previous,
                ProposedPassword: proposed
            },
            'POST'
        );
    };

    // Kicks off a native authentication flow against the Cognito API
    verifyTokenSecret = async ({ session, code }) => {
        return await this.handler(
            'VerifySoftwareToken',
            {
                Session: session,
                UserCode: code
            },
            'POST'
        );
    };

    // Kicks off a native authentication flow against the Cognito API
    getTokenSecret = async ({ session }) => {
        return await this.handler(
            'AssociateSoftwareToken',
            {
                Session: session
            },
            'POST'
        );
    };
    
    challengeResponder = async (ChallengeName, responseObject, challengeSession) => {

        // Challenges we expect to receive:
            // MFA_SETUP
            // SOFTWARE_TOKEN_MFA

        return await this.handler(
            'RespondToAuthChallenge',
            {
                ChallengeName: ChallengeName,
                ChallengeResponses: responseObject,
                Session: challengeSession
            },
            'POST'
        )
    }

    
    // Signs out the user (specified by access token) from all active sessions
    logout = async token => {
        return await this.handler(
            'GlobalSignOut',
            {
                AccessToken: token
            },
            'POST'
        )
    }

    // Returns updated tokens given a users refresh token.
    // Caching these values is the application's responsibility...should it be?
    refresh = async refreshToken => {

        // Consider implementing status/header checks for improved error handling
        const { response, status, headers } = await this.handler(
            'InitiateAuth',
            {
                AuthFlow: 'REFRESH_TOKEN',
                AuthParameters: {
                    REFRESH_TOKEN: refreshToken
                }
            },
            'POST'
        );

        return {
            refresh_token: refreshToken,
            access_token: response.AuthenticationResult.AccessToken,
            id_token: response.AuthenticationResult.IdToken,
            expiration: Math.floor(Date.now() / 1000) + response.AuthenticationResult.ExpiresIn
        }
    }

    // Returns attributes for the user from the cognito API
    userinfo = async (accessToken) => {
        const userinfoDomain = this.config.domain + '/oauth2/userInfo';
        const userinfoOptions = {
          method: 'GET',
          credentials: 'include',
          headers: {
            'content-type': 'application/json',
            authorization: 'Bearer ' + accessToken
          }
        };

        const infoResponse = await fetch(userinfoDomain, userinfoOptions);
        const infoJSON = await infoResponse.json()

        if ('custom:aflacWritingNumber' in infoJSON) {
          const writingNum = infoJSON['custom:aflacWritingNumber'];
          const parseStringArray = string => {
            return /\]$/.test(string) ? (
              string
                .replace('[', '')
                .replace(']', '')
                .split(',')
                .map(num => num.trim())
            ) : string;
          };
          if (typeof writingNum === 'string') {
            infoJSON['custom:aflacWritingNumber'] = parseStringArray(writingNum);
          };
        };
        return infoJSON;
      }

    // Returns attributes for the user from the cognito API
    updateAttributes = async ({ accessToken, attributes }) => {
        const attributeList = Object.keys(attributes).map(item => {
            return { Name: item, Value: attributes[item] }
        })
        return await this.handler(
            'UpdateUserAttributes',
            {
                "AccessToken": accessToken,
                "UserAttributes": attributeList
            },
            'POST'
        );
    }



    handler = async (action, request, method) => {

        // inject cognito client info into the request
        request.ClientId = this.config.directClientId

        const constructHeaders = iterator => {
            const headers = {}
            for (let pair of iterator) {
                headers[pair[0]] = pair[1]
            }
            return headers
        };

        let response = await fetch('https://cognito-idp.us-east-1.amazonaws.com/', {
            method: method,
            headers: {
                'x-amz-target': `AWSCognitoIdentityProviderService.${action}`,
                'content-type': 'application/x-amz-json-1.1',
            },
            body: (method == 'GET') ? null : JSON.stringify(request)
        });

        const headers = constructHeaders(response.headers);
        const status = response.status;

        response = await response.json();
        return { status, headers, response };
    }

        // switch (action) {
        //     case 'challenge':
        //         handler({ action: 'RespondToAuthChallenge', request: data })
        //         break;
        //     case 'change': break;
        //     case 'login': break;
        //     case 'logout': 
        //         handler({ action: 'GlobalSignOut', request: data })
        //         break;
        //     default: break;
        // }
}
const nativeAuth = new AuthDirect(authConfig)
export default nativeAuth