import { h, Component } from 'preact';
import { useContext, useEffect } from 'preact/hooks';
import PekinLoader from '../../components/PekinLoader';
import api from '../../Utilities/API/api';
import { CoreState } from '../../Utilities/contextLibrary';
import { route } from 'preact-router';

const Confirmation = (props) => {
    const {} = props;
    const urlParams = new URLSearchParams(window.location.search);
    const plan = urlParams.get('plan');

    const { coreState,  } = useContext(CoreState);
    const { tokens } = coreState;

    async function registerCheck() {
        let validate = await api.get(tokens.id_token, 'validate/owner/registration', 'apiv5');
        if (validate.status == 200) {
            return validate;
        } else {
            console.log("Validate Response: ", validate);
            setTimeout(() => { registerCheck(); }, 1000);
        }
    }
    useEffect(async() => {
        // check for plan from query params
        // Trigger the OFAC check
        await api.get(tokens.id_token, 'validate/owner/ofac', 'apiv5').then(res => {
            const { response, status } = res;
            if (status != 200) return alert(response);
        })
        // Check subscription endpoint for existence
        registerCheck().then(res => {
            if (res.status == 200) {
              console.log("Plan: ", plan);
              route('/dashboard');
            } else {
              route('/register/failed');
            }
        });

    },[plan])
    return (
        <div style={{
            background: "white",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-around",
            width: "100%",
            height: "100vh",
          }}>
            <PekinLoader />
        </div>
    );
};

export default Confirmation;
