import {h} from 'preact';

const Maintenance = () => {
    
    return (
        <div class={`maintenance`}>
            <h1>Please Excuse our dust.</h1>
            <p class="margin-x-auto">This section of our application is currently undergoing maintenance to provide our users a better experience, please reach out to support if you need help updating your account details or require access to your account records.</p>
        </div>

    )
}

export default Maintenance;