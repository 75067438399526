import apiConfig from './api.config'

class API {
  constructor (configObject) {
    this.config = configObject;
  };
    get = async (token, route, stage = 'apiv5') => {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        mode: 'cors',
        accept: '*/*'
      };

      let res = await fetch(this.config.domain + `/${stage}/${route}`, options);

      const status = res.status
      const headers = res.headers
      const response = res = await res.json()
      return {status, headers, response};
    }
    post = async (token, route, payload, stage = 'apiv5') => {
        const options = {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
              'content-type': 'application/json',
              authorization: 'Bearer ' + token
            },
            body: JSON.stringify(payload)
          };
          let res = await fetch(this.config.domain + `/${stage}/${route}`, options);
        
          const status = res.status
          const headers = res.headers
          const response = res = await res.json()
          return {status, headers, response};
    }
    put = async (token, route, payload, stage = 'apiv5') => {
        const options = {
            method: 'PUT',
            // mode: 'no-cors',
            // credentials: 'include',
            headers: {
              'content-type': 'application/json',
              authorization: 'Bearer ' + token
            },
            body: JSON.stringify(payload)
          };
          let res = await fetch(this.config.domain + `/${stage}/${route}`, options);
        
          const status = res.status
          const headers = res.headers
          const response = res = await res.json()
          return {status, headers, response};
    }
    delete = async (token, route, stage = 'apiv5') => {
        const options = {
            method: 'DELETE',
            mode: 'cors',
            accept: '*/*',
            credentials: 'include',
            headers: {
              'content-type': 'application/json',
              authorization: 'Bearer ' + token
            }
          };
          let res = await fetch(this.config.domain + `/${stage}/${route}`, options);
        
          const status = res.status
          const headers = res.headers
          const response = res = await res.json()
          return {status, headers, response};
    }
}

const api = new API(apiConfig);
export default api;