import { h } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { CoreState } from "../../Utilities/contextLibrary";
import api from "../../Utilities/API/api";

import Loader from "../Loading/loading";
import PaymentMethod from "./PaymentMethod";

export const Payments = ({ props }) => {
  const { customer, payments, subscription } = props;
  // const [ cardElement, setCardElement ] = useState(false);
  const [loading, setLoading] = useState(false);
  const { coreState } = useContext(CoreState);

  // Set the refs
  const remittance = useRef(null);
  const terms = useRef(null);
  const writing = useRef(null);
  const saved_payments = useRef(null);
  // Init input State
  const [isRemittance, setRemittance] = useState(false);
  const [isAgree, setAgree] = useState(false);

  // Handle Checkbox input events
  const handleAgree = (e) => {
    setAgree(e.target.checked);
    e.target.classList.remove(`invalid`);
  };

  // Populate from UserInfo
  const [writingNums, setWritingNums] = useState(null);

  useEffect(() => {
    setWritingNums(coreState.userinfo["custom:aflacWritingNumber"]);
    // setWritingNums(['EA123', 'BF345', 'KR567'])
  }, ["redundancyGate"]);
  // const addPaymentMethod = () => setCardElement(true);

  if (subscription && "payment_collection_method" in subscription) {
    console.log(
      "subscription.payment_collection_method: ",
      subscription.payment_collection_method
    );
    if (subscription.payment_collection_method == "remittance") {
      setRemittance(true);
      console.log("isRemittance: ", isRemittance);
    }
  }

  const handlePayment = async (event) => {
    let writing_number = document.getElementById("writing_number")?.value;
    setLoading(true);
    event.preventDefault();
    if (!isAgree) {
      terms.current.classList.add(`invalid`);
      return;
    }
    try {
      await api.put(coreState.tokens.id_token, "customer", {
        metadata: {
          writing_number: writing_number,
        },
      });
      await api.put(coreState.tokens.id_token, "customer/subscription", {
        remittance: true,
      });
      console.log(writing_number);
    } catch (error) {
      console.log("error: ", error);
    }
    setLoading(false);
    window.location.reload();
  };

  return (
    <section class={`payments`}>
      <column>
        <form class={`payment`} onsubmit={handlePayment}>
          {/* {isRemittance ? (
            <>
              <div class={`checkbox_container`}>
                <input
                  class={`terms`}
                  id="termsCheck"
                  ref={terms}
                  onChange={handleAgree}
                  type="checkbox"
                />
                <label for="termsCheck">
                  {" "}
                  I Agree with Aflac Statement Deduction Authorization terms?
                </label>
              </div>
              <select ref={writing} class={`writingNum`}>
                {Array.isArray(writingNums) ? (
                  writingNums.map((num) =>
                    customer.metadata.writing_number == num ? (
                      <option selected>{num}</option>
                    ) : (
                      <option>{num}</option>
                    )
                  )
                ) : (
                  <option>{writingNums}</option>
                )}
              </select>
            </>
          ) : ( */}
            <div class="margin-y-1" style={{ display: "flex", flexDirection: "column" }}>
              <PaymentMethod
                isRemittance={isRemittance}
                items={payments}
                writingNums={writingNums}
                />
            </div>
          {/* )} */}
          {loading ? (
            <Loader />
          ) : (
            <button class={`button -blue`} type="submit">
              Submit
            </button>
          )}
        </form>
        {/* )} */}
      </column>
      {isRemittance ? (
        <terms class={`commission_terms`} v-if="payByCommission">
          <h4>AFLAC – Individual Statement Deduction Authorization</h4>
          <h5>Participation Authorization</h5>
          <p>
            As an independent contractor authorized by agreement with Aflac to
            sell and solicit insurance policies for Aflac, I hereby authorize
            and request that Aflac, as an accommodation to me and expressly
            solely on my behalf, deduct the my monthly fees for Aflac Cyber
            Protection from my earned commissions, as shown on my monthly
            accounting statement, and transfer said amount on my behalf to Aflac
            Cyber Protection as payment for my use of Aflac Cyber Protection’s
            services.
          </p>
          <p>
            As part of this authorization, I acknowledge the following facts:
          </p>
          <ol>
            <li>
              1. Aflac has no ownership of, control over or liability for these
              monies, but has simply agreed to make the requested deductions on
              my behalf and for my convenience;
            </li>
            <li>
              2. My deductions are strictly voluntary and may be terminated by
              me upon written notice to Aflac Cyber Protection
            </li>
            <li>
              3. The Form 1099 provided by Aflac will continue to include the
              monthly fees as part of my gross commission income and I am solely
              responsible for determining whether I may be entitled to any
              business expense tax deduction for the amounts deducted herein.
            </li>
            <li>
              4. I am not authorized or entitled to use any title or other
              description of the independent contractor services provided by me
              to Aflac except as expressly set forth in a separate agreement
              with Aflac.
            </li>
          </ol>
          <h5>Agreement with Aflac Cyber Protection</h5>
          <p>
            I agree to the following in connection with the use of{" "}
            <strong>Aflac Cyber Protection’s</strong> service under the terms of
            my agreement with <strong>Aflac Cyber Protection</strong>:
          </p>
          <p>
            The <strong>Aflac Cyber Protection</strong> service monthly fees
            will be deducted from my commissions on a monthly basis.
          </p>
          <ol>
            <li>
              1. I may request cancellation at any time. To cancel, I email
              (cyberprotection@aflac.com) with my name, writing number and
              whatever else the vendor requires to identify customer and
              services provided.{" "}
            </li>
            <li>
              2. If I cancel after the 15th of any month, the cancellation will
              not take effect until the following month.
            </li>
            <li>
              3. If I am no longer providing services to Aflac as an independent
              contractor, the deduction will continue until I cancel.
            </li>
            <li>
              4. If a deduction does not take place due to an administrative
              issue, Aflac Cyber Protection will add my previous balance to the
              next month’s deduction.
            </li>
            <li>
              5. In order to qualify for Aflac’s discount Aflac Cyber Protection
              pricing, I agree my use of Aflac Cyber Protection is subject to
              Aflac Cyber Protection’s requirements
            </li>
          </ol>
          <p>
            Aflac may withhold any payments to Aflac Cyber Protection in the
            event a court order or applicable law so provides
          </p>
        </terms>
      ) : null}
    </section>
  );
};
