/* eslint-disable no-unused-vars */
import { h } from "preact";
import "../../style/components/blocks/button.css";

export default function Button(props) {
  const {
    disabled,
    blueOutine,
    black,
    blackOutline,
    orange,
    orangeOutline,
    red,
    redOutline,
    noOutline,
    label,
    onClick,
    children,
  } = props;

  return (
    <button
      {...props}
      className={`site-button ${props.className}`}
      onClick={onClick}
      role="button"
      aria-label={label}
    >
      {label && <span>{label}</span>}
      {children}
    </button>
  );
}
