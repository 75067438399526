/* eslint-disable no-unused-vars */
import { h } from "preact";
import StyledRichtext from "../StyledRichtext";

import "../../style/components/blocks/apc_comparison.css";

import CrowdStrikeLogo from "../../assets/images/crowdstrike-logo.svg";
import BackgroundImage from "../../assets/images/woman-ponder.png";
import IconCheckIncluded from "../../assets/images/icons/check-included.svg";
import IconCheckNotIncluded from "../../assets/images/icons/check-not-included.svg";

export default function ACPComparison(props) {
  const {
    title,
    description,
    image = BackgroundImage,
    logo = CrowdStrikeLogo,
    items,
  } = props;

  return (
    <div className="apc-comparison" {...props}>
      <div className="apc-comparison__content">
        <div className="apc-comparison__text">
          <h2 className="apc-comparison__title">{title}</h2>
          <div className="apc-comparison__description">
            <StyledRichtext content={description} />
          </div>
          <div className="apc-comparison__logo">
            <img
              src={logo}
              className="apc-comparison__logo-image"
              alt="CrowdStrike logo"
            />
          </div>
        </div>

        <div className="apc-comparison__details">
          <div className="apc-comparison__details-included">
            <div className="apc-comparison__details-included-row apc-comparison__details-included-row--heading">
              <div className="apc-comparison__details-included-col">
                <span className="apc-comparison__details-included-label apc-comparison__details-included-label--spacer" />
              </div>

              <div className="apc-comparison__details-included-col">
                <span className="apc-comparison__details-included-heading">
                  Aflac Cyber <br />
                  Protection
                </span>
              </div>
              <div className="apc-comparison__details-included-col">
                <span className="apc-comparison__details-included-heading">
                  Traditional <br />
                  Antivirus
                </span>
              </div>
            </div>

            {items &&
              items.map((item, index) => (
                <div
                  className="apc-comparison__details-included-row"
                  key={index}
                >
                  <div className="apc-comparison__details-included-col">
                    <span className="apc-comparison__details-included-label">
                      {item.label[0].text}
                    </span>
                  </div>

                  <div className="apc-comparison__details-included-col apc-comparison__details-included-col--icon">
                    <img
                      src={IconCheckIncluded}
                      className="apc-comparison__details-included-icon"
                      alt=""
                      role="presentation"
                    />
                  </div>
                  <div className="apc-comparison__details-included-col apc-comparison__details-included-col--icon">
                    {item.displaynotincluded && (
                      <img
                        src={IconCheckNotIncluded}
                        className="apc-comparison__details-included-icon"
                        alt=""
                        role="presentation"
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="apc-comparison__background-image">
        <img src={image} alt="" role="presentation" />
      </div>
    </div>
  );
}
