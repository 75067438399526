import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import BlogSection from '../../components/Blog Section/blog_section';
import Pricing from '../../components/CTA Block/pricing_block';
import { prismicBlogs, prismicClient } from '../../Utilities/prismic/prismicClient';
import Loader from '../../components/Loading/loading';

const Blog = () => {
	const [ docReady, setDocReady ] = useState(false)

	const [doc, setDocData] = useState(null);
	const [blog, setBlogData] = useState(null);
	useEffect(() => {
		prismicClient('homepage', 'homepage').then(res => {
			setDocData(res.data)
		})
		prismicBlogs('blog_post').then(res => {
			setBlogData(res.results)
		})
		setDocReady(true);
	}, ['redundancyGate']);
	return (
		(doc && blog) ? (
			<>
			<BlogSection blogs={blog}/>
			<Pricing content={doc}/>
			</>
		) : <Loader />
	)
};

export default Blog;
