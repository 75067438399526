import { route } from 'preact-router';
import Button from '../Button';
import StyledRichtext from "../StyledRichtext";

const Hero = ({ content }) => {
  const navigate = () => {
    route("/register");
  };
  return (
    <section id="we-protect-your-business" class={`hero`}>
      <div className="hero__content">
        <h2 class={`title -title display1`}>{content.hero_title[0].text}</h2>
        <div class={`summary -summary body`}>
          <StyledRichtext content={content.hero_copy} />
        </div>
        <p class={`action -action`}>
          <Button onClick={navigate} label={content.hero_link_label[0].text} />
        </p>
      </div>

      <div className="hero__background">
        <img
          src={content.hero_mobile_bg.url}
          className="hero__background-image hero__background-image--mobile"
          alt=""
          role="presentation"
        />
        <img
          src={content.hero_bg.url}
          className="hero__background-image hero__background-image--desktop"
          alt=""
          role="presentation"
        />
      </div>
    </section>
  );
};
export default Hero;
