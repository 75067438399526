/* eslint-disable no-unused-vars */
import { h } from "preact";
import { RichText } from "prismic-reactjs";

import "../../style/components/blocks/styled_richtext.css";

export default function StyledRichtext(props) {
  const { content } = props;

  return (
    <div className="styled-richtext">
      {content && <RichText render={content} />}
    </div>
  );
}
