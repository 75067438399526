import { h } from "preact";
import api from "../../Utilities/API/api";

export const CancelSubscription = ({ parent }) => {
  const { coreState, toggleCancelModal, subscription } = parent;
  const handleCancel = () => {
    try {
      api
        .delete(coreState.tokens.id_token, "customer/subscription")
        .then((res) => {
          console.log("Sub Cancel response: ", res.response);
          window.location.reload();
        });
    } catch (e) {
      console.log("error: ", e);
    }
  };
  const resumeSub = () => {
    try {
      api
        .put(coreState.tokens.id_token, "customer/subscription", {
          resume: true,
        })
        .then((res) => {
          console.log("resumeRes: ", res);
          window.location.reload();
        });
    } catch (e) {
      console.log("error: ", e);
    }
  };
  return (
    <section

      style={{
        background: "rgba(0, 0, 0, 0.6)", 
        width: "100%", 
        height: "100%", 
        position: "fixed", 
        top: "0",
        left: "0",
        display: "flex",
        alignItems: "center",
        zIndex: "9998"
        }}
    >
      <div
        style="z-index: 9999;
        max-width: 360px;
        align-items: center;
        background: var(--brand-white);
        margin: auto;
        min-width: 40%;
        padding: 20px;"
      >
        <img
          onClick={toggleCancelModal}
          style="filter: brightness(0%); position: relative; top: -3%; left: 90%;"
          src="/assets/images/icons/icon-close.svg"
        />
        <h2 class="display2 -extra-lite align-center margin-y-1">
          {subscription?.cancelling
            ? "Resume Subscription"
            : "Cancel Subscription"}
        </h2>
        <h4 class="display4 -extra-lite align-center margin-y-1">
          {
            subscription?.cancelling
              ? 'To confirm resuming your subscription click "Resume" button below.'
              : 'To confirm your cancellation click "Cancel" button below.'
          }
        </h4>
        <p style={{ fontWeight: 700, textAlign: "center", padding: "24px" }}>
         Subscription end date: {formatDate(subscription?.next_assesment_at)}
        </p>
        <div class="align-center">
          {subscription?.cancelling ? (
            <button onCLick={resumeSub} class="button -blue" type="submit">
              Resume
            </button>
          ) : (
            <button
              onCLick={handleCancel}
              class="button"
              style="background-color: var(--brand-red); color: var(--brand-white);"
              type="submit"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

const formatDate = (date) => {
  if (!date) return "";
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(new Date(date * 1000));
};