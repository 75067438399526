import { useContext, useRef, useState } from "preact/hooks";
import authWrapper from "../../Utilities/authGarage/authGarage";
import { CoreState } from "../../Utilities/contextLibrary";
import nativeAuth from "../../Utilities/authGarage/native";

const CredentialEntry = ({context}) => {
    const { authState, setAuthState } = useContext(context);
    const [ errorState, setErrorState ] = useState();

    const refs = {
        email: useRef(""),
        password: useRef("")
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const { status, headers, response } = await nativeAuth.login({
            username: refs.email.current.value,
            password: refs.password.current.value
        })
        if (status != 200) {
            // console.log('error: ', response);
            setErrorState({
                isError: true,
                errorMessage: response.message
            });
            if (response?.message?.includes('User is not confirmed.')) {
                setAuthState({...authState,
                    user: {
                        email: refs.email.current.value,
                        pass: refs.password.current.value
                    },
                    stage: 'confirm-email'
                })
            }
        } else {
            // Move on to TOTP
            setAuthState({...authState,
                stage: 'token-mfa',
                user: {
                    email: refs.email.current.value,
                    session: response.Session
                }
            });
        }
    
    }
    return (
        <section class="align-center margin-auto padding-medium">
            <div class="column col-1-3 margin-x-auto">
                <h1 class="display1 -extra-lite align-center">Sign in</h1>
                <p class="-dark-gray align-center">Sign in or create an account manually.</p>
                {errorState?.isError ? <p class="error">{errorState?.errorMessage}</p> : null}
                <form onSubmit={handleSubmit}>
                    <div class="input_container align-left">
                        <input 
                            ref={refs.email}
                            required
                            type="email" 
                            id="email" 
                            name="email" />
                        <label for="email">Email</label>
                    </div>
                    <div class="input_container align-left">
                        <input 
                            ref={refs.password}
                            required
                            type="password" 
                            id="password" />
                        <label for="password">Password</label>
                    </div>
                
                    <button class="button -blue margin-y-1 margin-x-auto">Login</button>
                </form>
                <br/>
                <p class="align-center -lite">Don't have an account yet? <a class="link" href="?stage=sign-up">Create one</a></p>
            </div>
            <div class="-line" />
            <div class="row space-around">
                <span class="col-1-3" style="width: fit-content;">
                    <h2 class="display2 -extra-lite">How registration works</h2>
                    <ol class="align-left list-circle">
                        <li>Create a starter account</li>
                        <li>Finish account registration</li>
                        <li>Complete Purchase</li>
                    </ol>
                </span>
                <span class="col-1-3">
                    <img src="/assets/images/support_duck.png" alt="support desk duck with headset" />
                    <p>
                        And if you need help, that's what we're here for. 
                        <br/> 
                        <a href="mailto:cyberprotection@aflac.com">cyberprotection@aflac.com</a>
                    </p>
                </span>
            </div>
            
        </section>
    )
}

export default CredentialEntry;