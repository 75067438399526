import React from "react";
import { h } from "preact";
import { useCallback, useEffect, useRef, useContext, useState } from "preact/hooks";
import { PlanSelectionContext } from "../PlanSelectionContext/PlanSelectionContext";
import { CoreState } from "../../Utilities/contextLibrary";
import api from "../../Utilities/API/api";
import InputField from "../InputField";
import "../../style/components/blocks/order_summary_widget.css";
import Button from "../Button";

export default function OrderSummaryWidget(props) {
  const {
    // handleSubmission,
    title,
    protectionPlanLabel,
    deviceCountLabel,
    deviceCountTotal,
    termsLabel,
    couponLabel,
    isReferralCode,
    formRef
  } = props;
  const {coreState, coreDispatch } = useContext(CoreState);
  const { userinfo, tokens } = coreState;
  const { planDetails, payByComission, formData, isFormValid } = useContext(PlanSelectionContext);

  const local = Boolean(process.env.PREACT_APP_COGNITO_APP_URL.includes('localhost:8080'));

  // reference the number values so we can
  // use math to calculate the actual totals
  const planTotalRef = useRef(null);
  const deviceTotalRef = useRef(null);
  const todaysTotalRef = useRef(null);
  const monthlyTotalRef = useRef(null);
  const referralCode = useRef(null);
  const widgetFormRef = useRef(null);

  //  terms state determines if the user has accepted the terms and can submit the form
  const termsRef = useRef(null);
  const [terms, setTerms] = useState(false);

  // reference the referral input and set the value
  // referral state determines visibility of referral form
  const hasRefferal = useRef(false);
  const [ referral, setReferral ] = useState(false);
  const handleReferral = () => { setReferral(!referral)};

  const handleSubmission = useCallback(async(e) => {
    document.getElementById('submit').disabled = true;
    e.preventDefault();
    // Set customer payload for fetch
    const replacePhone = formData.get('phone')
    const phone = replacePhone.replace(/\D/g, "");
    const customerPayload = {
      name: {
        first: formData.get('first_name') || userinfo.given_name  || "",
        last: formData.get('last_name') || userinfo.family_name  || ""
      },
      phone: phone || "",
    }
    const organizationPayload = {
      name: formData.get('business_name') || "" ,
      address: {
          line1: formData.get('street_address') || "",
          line2: formData.get('street_address_2') || "",
        },
      city: formData.get('city') || "",
      state: formData.get('state') || "",
      zip: formData.get('zip') || ""
    }
    if (formData.get('writing_number'))  customerPayload.metadata = { writing_number: formData.get('writing_number') };

    console.log("Customer Payload: ", customerPayload);
    console.log("Organization Payload: ", organizationPayload);
    // Create customer first
    if (window.location.href.includes('register')) {
      try {
        // check if cognito provider is google
        if (userinfo.provider === 'Google') {
          const attributes = {
            given_name: formData.get('first_name'),
            family_name: formData.get('last_name')
          }
          console.log("Access Token: ",accessToken);
          authWrapper.updateAttributes({ accessToken, attributes }).then(res => {
            console.log('User Attr Update: ', res);
            authWrapper.refresh(coreState.tokens.refresh_token).then(res => {
              const { response, status } = res;
              if (status !== 200) {
                console.log('Error refreshing tokens: ', response);
                return;
              } else {
                refreshResponse = {
                  refresh_token: coreState.tokens.refresh_token,
                  access_token: response.AuthenticationResult.AccessToken,
                  id_token: response.AuthenticationResult.IdToken,
                  expiration: Math.floor(Date.now() / 1000) + response.AuthenticationResult.ExpiresIn
                }
                coreDispatch({type: 'tokens', payload: refreshResponse})
                coreDispatch({
                  type: 'userinfo',
                  payload: {
                    ...authWrapper.userinfo(refreshResponse.id_token),
                    ...attributes
                  }
                });
              }
            })
          }).catch((err) => {
            console.log("Error updating user attributes: ", err);
          })
        }
        const customer = await api.post(tokens.id_token, 'customer/', customerPayload, 'apiv5')
        const organization = await api.post(tokens.id_token, 'organization/', organizationPayload, 'apiv5')
        if ((customer.status != 200 || organization.status != 200)) {
          alert("Error creating your account. Please try again later.");
          return route('/register/failed');
        }
      } catch (error) {
        alert("Customer Creation Error: ", error.message);
        return;
      }
    } else {
      try {
        const customer = await api.put(tokens.id_token, 'customer/', customerPayload, 'apiv5')
        if (customer.status != 200) {
          alert("Error updating your account. Please try again later.");
          return route('/register/failed');
        }
        const organization = await api.put(tokens.id_token, 'organization/info', organizationPayload, 'apiv5')
        if ((customer.status != 200 || organization.status != 200)) {
          alert("Error updating your account. Please try again later.");
          return route('/register/failed');
        }
      } catch (error) {
        alert("Organization Creation Error: ", error.message);
        return;
      }

    }


    let checkoutQueryParams = new URLSearchParams();
    if (local) checkoutQueryParams.append('local', 'yes')
    checkoutQueryParams.append('plan', planDetails.type);
    if (isReferralCode) {
      console.log("Referral code: ", referralCode.current.value);
      // console.log("Referral code: ", formData.get('referral_code'));
      checkoutQueryParams.append('refcode', referralCode.current.value);
    }
    console.log(checkoutQueryParams.toString())

    // Create payload for stripe checkout session creation

      const checkout = await api.get(tokens.id_token, `billing/checkout/pbc?${checkoutQueryParams}`, 'apiv5')
      if (!(checkout.status == 200)) return alert(checkout.response.message);
      window.location.assign(checkout.response.sessionURL);
      console.log("Checkout:", checkout);
  }, [planDetails, formData]);

  useEffect(() => {
    console.log("Form Ref: ", formRef);
  }, [formRef]);

  return (
    <>
      {payByComission && (
      <div className="order-summary-widget" {...props}>
        <h3 className="order-summary-widget__title">{title}</h3>

        <div className="order-summary-widget__content">
          <ul className="order-summary-widget__pricing-list">
            <li className="order-summary-widget__pricing-list-item">
              <span className="order-summary-widget__pricing-description">
                {protectionPlanLabel}
              </span>
              <span
                className="order-summary-widget__pricing-total order-summary-widget__pricing-total--currency"
                ref={planTotalRef}
                // data-value={convertDollars.format(quote.upfront.amount_subtotal / 100)}
              >
                14.99
                {/* {convertDollars.format(quote.upfront.amount_subtotal / 100)} */}
              </span>
            </li>

            <li className="order-summary-widget__pricing-list-item">
              <span className="order-summary-widget__pricing-description">
                {deviceCountLabel}
              </span>
              <span
                className="order-summary-widget__pricing-total"
                ref={deviceTotalRef}
                data-value={deviceCountTotal}
              >
                {deviceCountTotal}
              </span>
            </li>

            <li className="order-summary-widget__pricing-list-item">
              <span className="order-summary-widget__pricing-description">
                First 3 months
              </span>
              <span
                className=""
                ref={monthlyTotalRef}
                // data-value={convertDollars.format(quote.upfront.amount_total / 100)}
              >
                Free
                {/* {convertDollars.format(quote.upfront.amount_total / 100)} */}
              </span>
            </li>
            <li className="order-summary-widget__pricing-list-item">
              <span className="order-summary-widget__pricing-description">
                Monthly amount after 3 mo.
              </span>
              <span
                className="order-summary-widget__pricing-total order-summary-widget__pricing-total--currency"
                ref={monthlyTotalRef}
                // data-value={convertDollars.format(quote.upfront.amount_total / 100)}
              >
                14.99
                {/* {convertDollars.format(quote.upfront.amount_total / 100)} */}
              </span>
            </li>

            <li className="order-summary-widget__pricing-list-item">
              <div className="order-summary-widget__pricing-list-item-divider" />
            </li>

            <li className="order-summary-widget__pricing-list-item">
              <span
                className="order-summary-widget__pricing-description"
                // ref={}
              >
                Monthly Statement Deduction
              </span>
              <span
                className="order-summary-widget__pricing-total order-summary-widget__pricing-total--currency"
                ref={todaysTotalRef}
                // data-value={convertDollars.format(quote.upfront.amount_total / 100)}
              >
                14.99
              </span>
            </li>
          </ul>

          <form
            className="order-summary-widget__form"
            ref={widgetFormRef}
            onSubmit={handleSubmission}
          >
            <div className="order-summary-widget__form-item">
              <input
                type="checkbox"
                required
                form="order-summary-form"
                name="terms"
                id="terms"
                className="order-summary-widget__form-checkbox"
                onChange={(e) => setTerms(e.target.checked)}
                />
              <label
                ref={termsRef}
                className="order-summary-widget__form-label"
                for="terms"
                style={terms ? {color: '#444'} : null}
                >
                {/* {termsLabel} */}
                I agree with the <a href="/terms" target="_blank"> Terms and Conditions</a>
              </label>
            </div>
            <div className="order-summary-widget__form-item">
              <input
                type="checkbox"
                name="referral"
                form="order-summary-form"
                id="referral"
                ref={hasRefferal}
                onChange={handleReferral}
                className="order-summary-widget__form-checkbox"
              />
              <label className="order-summary-widget__form-label" for="referral">
                {couponLabel}
              </label>
            </div>

            {referral && <div className="order-summary-form__form-item span-12">
                  <InputField
                    pattern={"[A-Za-z0-9]{12}"}
                    required={true}
                    form={"order-summary-form"}
                    id={"referral_code"}
                    label={"Referral Code"}
                    type={"text"}
                    ref={referralCode}
                    name={"referral_code"}
                  />
                </div>}

            <div className="order-summary-widget__form-item order-summary-widget__form-item--submit">
            <div className="span-12">
              <input type="submit" id="submit" class="site-button" disabled={!isFormValid || !terms } value="Continue to Checkout">
                Continue to Checkout
              </input>
            </div>
            </div>
          </form>
        </div>
      </div>
      )}
    </>
  );
}
