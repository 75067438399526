import { h } from "preact";

import { useCallback, useRef, useState } from "preact/hooks";

import "../../style/components/blocks/input_field.css";

export default function InputField(props) {
  const {
    value,
    label,
    name,
    placeholder,
    type,
    required = false,
    onChange,
    ref,
    isValidated = true,
    validateLabel = "* There was an issue with this entry. Please try again",
  } = props;

  const fieldRef = useRef(null);

  const handleFocus = useCallback(() => {
    fieldRef.current.classList.add("input-field--active");
  }, [fieldRef]);

  const handleBlur = useCallback(() => {
    fieldRef.current.classList.remove("input-field--active");
  }, [fieldRef]);

  return (
    <div className="input-field" ref={fieldRef}>
      {label && (
        <label
          class={`input-field__label ${
            !isValidated && "input-field__label--is-not-valid"
          }`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        name={name}
        required={required}
        className={`input-field__input ${
          !isValidated && "input-field__input--is-not-valid"
        }`}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />

      <div
        className={`input-field__validate ${
          !isValidated && "input-field__validate--is-not-valid"
        }`}
      >
        <span className="input-field__validate-label">{validateLabel}</span>
      </div>
    </div>
  );
}
