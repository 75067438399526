import {h} from 'preact';
import { useState, useEffect, useContext, useRef } from 'preact/hooks';
import api from '../../../../Utilities/API/api';
import { CoreState } from '../../../../Utilities/contextLibrary';

import Devices from '../../../../components/Devices/devices';
import Issues from '../../../../components/Issues';
import Loader from '../../../../components/Loading/loading';
import { route } from 'preact-router';

const EditEmployee = ({ parent }) => {
    const {toggleEditModal, member, coreState} = parent;
    const refs = {
        name: {
            first: useRef(null),
            last: useRef(null)
        },
        email: useRef(null),
        phone: useRef(null),
        type: useRef(null)
    }
    console.log('member: ', member);
    const handleSubmit = async event => {
        event.preventDefault();

        const putPayload = {
            name: {
                first: refs.name.first.current.value,
                last: refs.name.last.current.value
            },
            email: refs.email.current.value,
            phone: refs.phone.current.value,
            type: refs.type.current.value
        }
        const {status, headers, response} = await api.put(coreState.tokens.id_token, `employee/${member.CLIENTUUID}`, putPayload);
        console.log('employee updates response: ', response);
        if (status != 200) {
            console.log('employee update status: ', status);
        } else {
            route('/dashboard/team');
        }
    }
    return (
        <section style="background: rgba(0, 0, 0, 0.6); 
                width: 100%; 
                height: 100%; 
                position: fixed; 
                top: 0; 
                left: 0;
                display: flex;
                align-items: center;">
            <div style="z-index: 1;
                    max-width: 360px;
                    background: var(--brand-white);
                    margin: auto;
                    min-width: 40%;
                    padding: 20px;">
                <img onClick={toggleEditModal} style="filter: brightness(0%); position: relative; top: -3%; left: 90%;" src="/assets/images/icons/icon-close.svg" />
                <h2 class="display2 -extra-lite align-center margin-y-1">Edit Info</h2>
                <form onSubmit={handleSubmit}>
                    <div class={`${`input_container`}`}>
                        <input 
                        ref={refs.name.first}
                        required
                        type="text"
                        id="firstName"
                        value={member?.name?.first}
                        />
                            <label for="firstName">First Name*</label>
                    </div>
                    <div class={`${`input_container`}`}>
                        <input 
                        ref={refs.name.last}
                        required
                        type="text"
                        id="lastName"
                        value={member?.name?.last}
                        />
                            <label for="lastName">Last Name*</label>
                    </div>
                    <div class={`input_container`} >
                        <input 
                        ref={refs.email}
                        required
                        type="text"
                        id="email"
                        value={member?.email}
                        />
                            <label for="email">Email*</label>
                    </div>
                    <div class={`input_container`} >
                        <input 
                        ref={refs.phone}
                        required
                        type="phone"
                        id="phone"
                        value={member?.phone}
                        />
                            <label for="phone">Phone*</label>
                    </div>
                    <div class={`input_container`} >
                        <select 
                        ref={refs.type}
                        required
                        type="select"
                        id="type"
                        >
                            <option class="-lite" value="employee">Team Member</option>
                            <option value="admin">Admin</option>
                        </select>
                            <label for="type">Role</label>
                    </div>
                    <button class="button -blue margin-y-1" style="width: 100%;" type="submit">Add User</button>
                </form>
            </div>
        </section>
    )
}

export default () => {
    const { coreState, coreDispatch } = useContext(CoreState);
    const { tokens } = coreState;
    const [ devices, setDevices ] = useState();
    const [ issues, setIssues ] = useState();
    const [ member, setMember ] = useState();
    const [ editModal, setEditModal ] = useState(false);
    const [ id, setId] = useState(null)

    const toggleEditModal = () => {
        setEditModal(prev => !prev);
    }
    const deleteUser = () => {
        let params = new URLSearchParams(window.location.search);
        // console.log('params: ', params);
        const id = params.get("id");
        api.delete(tokens.id_token, `employee/${id}`).then(res => {
            // console.log('deleteRes: ', res);
            route('/dashboard/team');
        });
    }
    useEffect(async() => {
        let params = new URLSearchParams(window.location.search);
        // console.log('params: ', params);
        setId(params.get("id"));
        api.get(tokens.id_token, `employee/${params.get("id")}`).then(res => {setMember(res.response);});
        api.get(tokens.id_token, `employee/${params.get("id")}/devices`).then(res => {setDevices(res.response);});
        api.get(tokens.id_token, `employee/${params.get("id")}/alerts`).then(res => {setIssues(res.response);});

    }, ['redundancyGate']);

    return (
        <>
            { !member || !devices || !issues || !id ? <Loader /> : (
                <>
                <section style="height: 100%;" class="bg-white padding-large" >
                    <a href="/dashboard/team" class="button back">Back to Team</a>
                    <div class="row margin-y-1 space-between">
                        <div class="row | margin-y-1 | space-between | user-head">
                            <span class="user-icon">
                                <div class="display1 -extra-lite flex justify-center round bg-lighter-blue badge" style={`--width: 96px; --badge: url('/assets/images/icons/icon-badge-${member?.issues == 0 ? 'gold' : member?.issues == 1 ? 'silver' : 'bronze'}.svg'); align-items: center; color: var(--brand-blue);`}>
                                    <span style="position: relative; left: 14%;">{member?.name.first.split("")[0]}{member?.name?.last.split("")[0]}</span>
                                </div>
                            </span>
                            <span class="user-info">
                                <h2 class="display2 -extra-lite">{member?.name.first} {member?.name?.last}</h2>
                                {member?.type}
                                <br/>
                                {member?.email}
                                <br/>
                                {member?.phone}
                            </span>
                        </div>
                        <span class="user-actions space-between">
                                <button onCLick={toggleEditModal} class="button -blue"><img style="margin-right: 10px;" src="/assets/images/icons/edit-icon.svg" /> Edit Info</button>
                                <button onClick={deleteUser} class="button -transparent -remove" style="--button-color-b: var(--brand-red); margin-left: 10px;"><img style="margin-right: 10px;" src="/assets/images/icons/icon-trash.svg" /> Remove</button>
                        </span>
                    </div>
                    <div class="row space-between">
                        <span class="col-1-3">
                            <h3 class="display3b -lite">{member?.devices} of 3 Devices Used</h3>
                            {devices ? <Devices devices={devices} /> : null}
                        </span>
                        <span class="col-2-4">
                        {issues?.length == 0 ? null : <h3 class="display3b -lite">{issues?.length} open issue{issues?.length > 1 ? 's' : null}</h3>}
                            {issues ? <Issues issues={issues} /> :  null}
                        </span>
                    </div>
                {editModal && <EditEmployee parent={{ toggleEditModal, member, coreState }} />}
                {editModal && <overlay class="overlay"/>}
                </section>
                </>
            )}
        </>
    )
}
