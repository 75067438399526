import {h} from 'preact';

import { prismicClient } from '../../Utilities/prismic/prismicClient';
import { useEffect, useState } from 'preact/hooks';


const NotFound = () => {
    const [doc, setDocData] = useState();

    useEffect(() => {
        prismicClient('notfound', 'notfound').then(res => {
			setDocData(res.data)
		})
    }, ['redundancyGate'])
    return (
        doc ? (
        <section class="notFound margin-x-auto">
            <div class="col-2-4 margin-x-auto">
                <h1>{doc['404_title'][0].text}</h1>
                <h3>This page does not exist</h3>
                <p>{doc['404_blurb'][0].text}</p>
                <ul>
                    {doc['404_links'].map( links => <li><a href={links.link.url}>{links.label[0].text}</a></li>)}
                </ul>
                <p>{doc['404_contact_us'][0].text}</p>
                <p><a href={doc['404_contact_us'][1].spans[0].data.url}>{doc['404_contact_us'][1].text}</a></p>
            </div>
        </section>
        ) : null
    )
};

export default NotFound;